<template>
  <button @click="$modal.hide('confirmDelete')" class="close">🞨</button>
  <div class="reallyDelete">
    <div class="confirmDeleteBackground">
    <p class="confirmDeleteText">
      {{ $t("rightClickMenu.delete") }}
    </p>
    </div>
    <button @click.stop="deleteRecord()"  class="confirmButton"> {{ $t("home.savedExpressions.yesbtn") }} </button>
    <button @click="$modal.hide('confirmDelete')" class="rejectButton"> {{ $t("home.savedExpressions.nobtn") }} </button>
  </div>
</template>

<script>

import { mounted } from '../../main'
import eventBus from '../../eventBus'
import { getBackendMixin } from '../../mixins/getBackendUrl'

import axios from "axios"

export default {
  name: 'DeleteRecordModal',
  mixins: [ getBackendMixin ],
  data() {
    return {
      activeItem: ""
    }
  },
  methods: {
     focusTab (tabName) {
      this.activeItem = tabName
      if (tabName === '#MyProfileContent') {
        this.getSavedExpressions()
      } else if (tabName === '#MostSearchedResults') {
        this.getSearchData('#MostSearchedResults')
      } else if (tabName === '#lastSearchedTerms') {
        this.getHistoryExpressions()
      }
    },
    deleteRecord () {
      // facade way
      var id = this.$modal.id

      // solr way
      var record = {}
      record.delete = {}
      record.delete.id = id

      if (mounted.$data.useBackendFacade) {
        axios.delete(this.getBackendMixin('deleteStored') + '/' + id).then(
          response => {
            eventBus.$emit('updateMyProfile', 'saved')
            eventBus.$emit('errorOccured', { msg: 'Nepodarilo sa zmazať záznam:' + response.status })
          },
        )
      } else {
        axios.post(this.getBackendMixin('deleteStored'), record).then(
          response => {
            eventBus.$emit('updateMyProfile', 'saved')
            eventBus.$emit('errorOccured', { msg: 'Nepodarilo sa zmazať záznam:' + response.status })
          },
        )
      }
      this.$modal.hide('confirmDelete')
    },
    getSavedSearch() {
      axios.get(this.getBackendMixin('identity')).then(response => {
        var username = mounted.$data.useBackendFacade ? response.body.username : response.body.identity.username
        // delim contains also a string for solr as a way to compensate for a bug in the solr way of accessing user specific data
        var delim = mounted.$data.useBackendFacade ? '/' : '?fq=user%3A' + encodeURIComponent(username.replace(/:/g, '\\:')) + '&rows='
        axios.get(this.getBackendMixin('getStored') + delim + mounted.$data.maximumSavedPhrase).then(
          response => {
            if (response.data.response !== undefined) {
              this.searchData.savedExpression = response.data.response.docs
            }
            this.loadingVisibility = false
            eventBus.$emit('errorOccured', { msg: 'Môj profil sa nepodarilo načítať:' + response.status })
          },
        )
      })
    }
  }
}
</script>

<style lang="scss" scoped src="@/assets/css/DeleteRecordModal.scss"/>