<template>
    <div class="myProfileContentView">
        <p> {{ $t("savedExpression") }} </p>
        <div class="savedexpression deleteview" v-for="el in this.savedExpressions" :key="el">
            {{ this.$store.state.savedExpressions }} <button @click="testModal()" class="deletebutton"> {{ $t("rightClickMenu.delete") }} </button>
        </div>
        <v-pagination
            v-model="page"
            :pages="setMaxPage()"
            :range-size="1"
            active-color="#AAAAAA"
            @update:modelValue="setPageSearch()"
        />
    </div>
    <teleport to="body">
        <div class="modalHelp" v-show="showDeleteModal">
            <div class="modalViewHelp">
                <DeleteRecordModal/>
            </div>
        </div>
    </teleport>
</template>

<script>
import DeleteRecordModal from "../MyProfile/DeleteRecordModal.vue"
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import { mounted } from "../../main"

export default {
    name: "MyProfileContent",
    inheritAttrs: false,
    components: {
        DeleteRecordModal, VPagination
    },
    data(){
        return {
            showDeleteModal: false,
            page: 1,
            savedExpresions: this.$store.state.savedExpressions
        }
    },
    methods: {
        testModal() {
            this.showDeleteModal != this.showDeleteModal;
            console.log(this.showDeleteModal);
        },
        setMaxPage () {
            return Math.ceil(this.$store.state.numFound / this.$root.recordsPerPage)
        },
        setPageSearch() {
            this.$store.commit('setPageNumber', this.page)
            this.$store.dispatch('myProfileSearch', {})
            console.log(this.savedExpresions)
        },
    }
}
</script>

<style lang="scss" scoped src="@/assets/css/MyProfileContent.scss"></style>