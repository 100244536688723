<template>
    <div class="facetContent">
        
        <!-- Date Filter -->
        <div class="facetCategories">
            <p class="filterCategoriesTitle"> {{ $t("metadataFieldLabels.creationDate") }} </p>
            <button v-if="!toggles.dateOfCreation" @click="toggles.dateOfCreation = !toggles.dateOfCreation"><img src="../../assets/plus.svg"> </button>            
            <button v-else-if="toggles.dateOfCreation" @click="toggles.dateOfCreation = !toggles.dateOfCreation"> <img src="../../assets/minus.svg"> </button>
        </div>
        <div class="facetAttributes" v-show="toggles.dateOfCreation">
            <div class="datePicker">
                <label for="startDate">Start Date:</label>
                <input id="startDate" class="dateInput" type="date" v-model="filter.startDate">
            </div>
            <div class="datePicker">
                <label for="endDate">End Date:</label>
                <input id="endDate" class="dateInput" type="date" v-model="filter.endDate">
            </div>
        </div>
        
        <!-- Document Source Filter -->
        <div class="facetCategories">
            <p class="filterCategoriesTitle"> Document source </p>
            <button v-if="!toggles.sourceFilter" @click="toggles.sourceFilter = !toggles.sourceFilter"> <img src="../../assets/plus.svg"> </button>
            <button v-else-if="toggles.sourceFilter" @click="toggles.sourceFilter = !toggles.sourceFilter"> <img src="../../assets/minus.svg"></button>
        </div>
        <div class="facetAttributes" v-show="toggles.sourceFilter">
            <select class="docTypeSelect" v-model="filter.source">
                <option value="all">All sources</option>
                <option value="disk">Disk</option>
                <option value="crz">CRZ</option>
            </select>
        </div>

        <!-- Name Filter -->
        <!-- <div class="facetCategories">
            <p class="filterCategoriesTitle"> Name search </p>
            <button v-if="!toggles.nameFilter" @click="toggles.nameFilter = !toggles.nameFilter"> <img src="../../assets/plus.svg"></button>
            <button v-else-if="toggles.nameFilter" @click="toggles.nameFilter = !toggles.nameFilter"> <img src="../../assets/minus.svg"> </button>
        </div>
        <div class="facetSearchBox" v-show="toggles.nameFilter">
            <vue3-simple-typeahead
                ref="nameFilterRef"
                id="nameFilter"
                class="inputfield"
                :placeholder="$t('searchfield')"
                :items="suggestList"
                :minInputLength="1"
                autocomvvplete="on"
                @onInput="onNameFilterInput" 
                @onBlur="onNameFilterBlur"
                @selectItem="onNameFilterSelect"
                v-model="filter.searchInput"
            />
        </div> -->

        <!-- Document Type Filter -->
        <!-- <div class="facetCategories">
            <p class="filterCategoriesTitle"> Document type </p>
            <button v-if="!toggles.typeFilter" @click="toggles.typeFilter = !toggles.typeFilter"> <img src="../../assets/plus.svg"> </button>
            <button v-else-if="toggles.typeFilter" @click="toggles.typeFilter = !toggles.typeFilter"> <img src="../../assets/minus.svg"></button>
        </div>
        <div class="oneFacetAttribute" v-show="toggles.typeFilter">
            <select class="docTypeSelect" v-model="filter.type">
                <option value="all">All Types</option>
                <option value="FSCACROBAT@1.1:PDFObject">PDF</option>
                <option value="COOMSOFFICE@1.1:WinWordObject">DOC</option>
                <option value="TXTDocument">TXT</option>
            </select>
        </div> -->

        <!-- Order By -->
        <div class="facetCategories">
            <p class="filterCategoriesTitle"> Order By </p>
            <button v-if="!toggles.orderFilter" @click="toggles.orderFilter = !toggles.orderFilter"> <img src="../../assets/plus.svg"> </button>
            <button v-else-if="toggles.orderFilter" @click="toggles.orderFilter = !toggles.orderFilter"> <img src="../../assets/minus.svg"></button>
        </div>
        <div class="facetAttributes" v-show="toggles.orderFilter">
            <select class="orderBySelect" v-model="filter.order">
                <option value="default">Relevance</option>
                <option value="dateAsc">Date - Ascending</option>
                <option value="dateDesc">Date - Descending</option>
                <option value="createdByAsc">Created By - Ascending</option>
                <option value="createdByDesc">Created By - Descending</option>
            </select>
        </div>

        <!-- Reset and Search Buttons -->
        <div class="facetControlButton">
            <button class="resetFacetButton" @click="filterReset"> {{ $t("leftSideMenu.filterReset") }} </button>
            <button class="facetSearch" @click="filterSearch"> {{ $t("leftSideMenu.searchbutton") }} </button>
        </div>
    </div>
</template>

<script>
import EnvProvider from 'jvjr-docker-env';
import getFacetQuery from '../../mixins/getFacetQuery'

export default {
    name: "LeftSideBar",
    data() {
        return {
            monthsLabels: ['január', 'február', 'marec', 'apríl', 'máj', 'jún', 'júl', 'august', 'september', 'október', 'november', 'december'],
            toggles: {
                dateOfCreation: true,
                sourceFilter: true,
                nameFilter: false,
                typeFilter: false,
                orderFilter: true,
            },
            filter: {
                startDate: '',
                endDate: '',
                source: 'all',
                searchInput: '',
                type: 'all',
                order: 'default',
            },
            suggestList: [],
        }
    },
    mixins: [
        getFacetQuery
    ],
    methods: {
        onNameFilterInput(event) {
            console.log('input changed: ' + event.input)
            clearTimeout(this.timeOutForTermChanged)
            this.timeOutForTermChanged = setTimeout(this.termChanged(event), 200)
        },
        onNameFilterBlur(event) {
			this.filter.searchInput = event.input;
            console.log('input set: ' + event.input)
		},
        onNameFilterSelect(item) {
			this.filter.searchInput = item;
            console.log('input selection: ' + item)
		},
        termChanged(event) {
            try {
                var input = event.input;

                if (input.length !== 0) {
                    let suggestForm = new FormData()
                    suggestForm.append('q', input)

                    this.axios.post(EnvProvider.value('API_URL') + this.$root.API_SUGGEST, suggestForm, { 
                        headers: { 
                            "X-Forwarded-User": this.$store.state.loggedUser
                        }
                        }).then(result => {
                            if (result.status == 200) {
                                this.suggestList = result.data.suggestList
                            } else {
                                this.$store.commit('pushMsg', {type: 'error', text: 'Odporúčač výsledkov nepracuje správne'})
                            }
                        })
                    this.filter.searchInput = input;
                }
            } catch (err) {
                console.log('ERROR !', err)
                this.$store.commit('pushMsg', {type: 'error', text: 'Odporúčač výsledkov nepracuje správne'})
            }
        },
        formatDate(dateString) {
            // Parse the input date string
            const date = new Date(dateString);
            
            // Format the date to the required format (YYYY-MM-DDThh:mm:ssZ)
            const formattedDate = date.toISOString();

            return formattedDate;
        },
        escapeQueryString(query) {
            return query.replace(/([\!\*\+\&\|\(\)\[\]\{\}\^\~\?\:\"])/g, "\\$1");   
        },
        filterSearch(){
            console.log('Search parameters: ')
            console.log(this.filter)

            this.$store.commit('setSelectedTab', {})
            this.$store.commit('setSelectedTabs', {
                items: []
            })
            this.$store.commit('setFacetResults', [])
            this.$store.commit('setUriParameters', {
                searchPhrase: this.$store.state.uriParams.searchPhrase,
                itemsPerPage: this.$root.recordsPerPage,
                facetQuery: this.getFacetQuery('#All'),
                filterQuery: this.getFilterQuery(),
                idQuery: this.$store.state.uriParams.idQuuery,
                tabQuery: this.$store.state.uriParams.tabQuery,
                sortQuery: this.getSortQuery(),
            })
            
            this.$store.dispatch('search', {})
        },
        filterReset() {
            this.filter = {
                startDate: '',
                endDate: '',
                source: 'all',
                searchInput: '',
                type: 'all',
                order: 'default',
            }

        },
        getFilterQuery() {
            let filterQuery = ''

            // Date Filter
            if (this.toggles.dateOfCreation) {
                if (this.filter.startDate && this.filter.endDate) {
                    filterQuery += '&fq=creationDate:[' + this.formatDate(this.filter.startDate) + ' TO ' + this.formatDate(this.filter.endDate) + ']';
                } else if (this.filter.startDate) {
                    filterQuery += '&fq=creationDate:[' + this.formatDate(this.filter.startDate) + ' TO *]';
                } else if (this.filter.endDate) {
                    filterQuery += '&fq=creationDate:[* TO ' + this.formatDate(this.filter.endDate) + ']';
                }
            }

            // Name Filter
            if (this.toggles.nameFilter && this.filter.searchInput) {
                filterQuery += '&fq=name:' + this.escapeQueryString(this.filter.searchInput);
            }

            // Document Source Filter
            if (this.toggles.sourceFilter && this.filter.source !== 'all') {
                filterQuery += '&fq=sourceId:' + this.escapeQueryString(this.filter.source);
            }

            // Document Type Filter
            if (this.toggles.typeFilter && this.filter.type !== 'all') {
                filterQuery += '&fq=objectTypeId:' + this.escapeQueryString(this.filter.type);
            }
            
            return filterQuery
        },
        getSortQuery() {
            var sortQuery = ''

            // Order By Sort
            if (this.toggles.orderFilter && this.filter.order !== 'default') {
                if (this.filter.order === 'dateAsc') {
                    sortQuery += '&sort=creationDate asc';
                } else if (this.filter.order === 'dateDesc') {
                    sortQuery += '&sort=creationDate desc';
                } else if (this.filter.order === 'createdByAsc') {
                    sortQuery += '&sort=createdBy asc';
                } else if (this.filter.order === 'createdByDesc') {
                    sortQuery += '&sort=createdBy desc';
                }
            } 
            
            return sortQuery
        },
    }
}
</script>

<style lang="scss"  scoped src="@/assets/css/LeftSideBar.scss"/>