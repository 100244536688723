import de from "../lang/de/de.json"
import en from "../lang/en/en.json"
import sk from "../lang/sk/sk.json"
import cz from "../lang/cz/cz.json"
import hu from "../lang/hu/hu.json"

export default {
    de,
    en,
    sk,
    cz,
    hu
};