<template>
    <div class="searchContent">
        <div class="searchView">
            <SearchField v-if='$root.showContextSearch'></SearchField> 
            <SearchForm v-if='!$root.showContextSearch'></SearchForm>
        </div>
    </div>
</template>

<script>
import SearchField from "../components/Search/SearchField.vue"
import SearchPanel from "../components/Search/SearchPanel.vue"
import MyProfileContent from "../components/Search/MyProfileContent.vue"
import SearchForm from "../components/Search/SearchForm.vue"

export default {
    name: "Search",
    components: {
        SearchField,
        SearchForm,
        "myProfileContent": MyProfileContent,
        "searchpanel":SearchPanel,
    },
    data() {
        return {
            activeComponent: "SearchPanel",
        }
    },
    methods: {

    }
}
</script>

<style lang="scss" src="@/assets/css/views/Search.scss"></style>