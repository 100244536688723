<template>
  <div class="view">
    <p class="searchedresult"><strong>{{ $t("home.top10Expressions.foundExpression") }}</strong></p>
    <p class="searchcount"><strong> {{ $t("home.top10Expressions.numberOfSearching") }} <img style="position:absolute;" src="@/assets/Icondropdown.svg"> </strong></p>
      <div v-for="el in 7" :key="el">
        <searchexpr/>
      </div>
  </div>
</template>

<script>
import SearchExpr from "./MyProfileContent.vue"
export default {
  name: "SearchPanel",
  components: {
    "searchexpr":SearchExpr
  }
}
</script>

<style lang="scss" scoped src="@/assets/css/SearchPanel.scss"></style>