<template>
    <div class="termSearchView">
        <div class="searched">
            <p class="p0"> {{ $t("home.lastFoundExpressions.foundExpression") }} </p>
            <p class="p0"> {{ $t("home.top10Expressions.numberOfSearching") }} </p> 
        </div>
        <div class="terms">
            <div class="savedexpression" v-for="el in items" :key="el">
                <div class="line"></div>
                <div class="resultFlex">
                    <p class="p1"> {{ el.query }} </p> <p class="p2"> {{ el.count }} </p>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>

export default {
    name: "MostSearchedResults",
    inheritAttrs: false,
    computed: {
        items() {
            return this.$store.state.top10Expressions;
        }
    },
    components: {
        // MostSearchedResult
    },
    data (){
        return {
            page: 1,
        }
    },
    methods: {
        setMaxPage () {
            return Math.ceil(this.$store.state.numFound / this.$root.recordsPerPage)
        },
        setPageSearch() {
            this.$store.commit('setPageNumber', this.page)
            this.$store.dispatch('myProfileSearch', {})
            console.log(this.searchData.top10)
        },
    }
}
</script>

<style lang="scss" scoped src="@/assets/css/LastMostSearchedTerms.scss"/>