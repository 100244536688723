<template>
  <h1> {{ $t("notermssaved") }} </h1>
</template>

<script>
export default {
    name: "NotFound"
}
</script>

<style scoped>
    h1 {
        font-size: large;
    }
</style>