<template>
    <div class="profile-bounding">
        <div class="profile-header">
            <div class="header-container">
                <h1 class="profile-title-text">My Profile</h1>
                <button class="profile-exit-button" @click="closeModal">X</button>
            </div>
            <div class="tabs">
                <div class="tab" :class="{ active: activeTab === 'tab1' }" @click="activeTab = 'tab1'">
                    Most Recent Searches
                </div>
                <div class="tab" :class="{ active: activeTab === 'tab2' }" @click="activeTab = 'tab2'">
                    Most Searched Terms
                </div>
                <div class="tab" :class="{ active: activeTab === 'tab3' }" @click="activeTab = 'tab3'">
                    License Information
                </div>
            </div>
        </div>

        
        
        
        <div class="profile-main-section">
            <div class="profile-content">
                <div class="profile-tabs-content">
                    <div class="profile-tab-content" v-if="activeTab === 'tab1'">
                        <h2>Most Recent Searches</h2>
                        <!-- <div class="profile-setting-item">
                            <span>Data View:</span>
                            <div class="segmented-control">
                                <button class="left" :class="{ active: toggleGlobalData }" @click="
                                    toggleGlobalData = true;
                                loadData();
                                ">
                                    Global
                                </button>
                                <button class="right" :class="{ active: !toggleGlobalData }" @click="
                                    toggleGlobalData = false;
                                loadData();
                                ">
                                    User
                                </button>
                            </div>
                        </div> -->
                        <div v-if="loadingError" class="error-message">
                            {{ loadingError }}
                        </div>
                        <div v-else>
                            <div class="statistic-header">
                                <span class="stat-name-header">Term</span>
                                <span class="stat-value-header">Date Searched</span>
                            </div>
                            <div v-for="stat in items" :key="stat" class="statistic">
                                <span class="stat-name">{{ stat.query }}</span>
                                <span class="stat-value">{{ stat.time }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="profile-tab-content" v-if="activeTab === 'tab2'">
                        <h2>Most Searched Terms</h2>
                        <!-- <div class="profile-setting-item">
                            <most-searched-results/>
                            <span>Data View:</span>
                            <div class="segmented-control">
                                <button class="left" :class="{ active: toggleGlobalData }" @click="
                                    toggleGlobalData = true;
                                loadData();
                                ">
                                    Global
                                </button>
                                <button class="right" :class="{ active: !toggleGlobalData }" @click="
                                    toggleGlobalData = false;
                                loadData();
                                ">
                                    User
                                </button>
                            </div>
                        </div> -->
                        <div v-if="loadingError" class="error-message">
                            {{ loadingError }}
                        </div>
                        <div v-else>
                            <div class="statistic-header">
                                <span class="stat-name-header">Term</span>
                                <span class="stat-value-header">Count</span>
                            </div>
                            <div v-for="stat in items_searched" :key="stat" class="statistic">
                                <span class="stat-name">{{ stat.query }}</span>
                                <span class="stat-value">{{ stat.count }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="profile-tab-content" v-if="activeTab === 'tab3'">
                        <h2>License Information</h2>
                        <div v-if="licenseInfo">
                            <div v-if="licenseInfo.expiration_date" class="statistic">
                                <span class="stat-name">Expiration date</span>
                                <span class="stat-value">{{
                                    licenseInfo.expiration_date
                                }}</span>
                            </div>
                            <div v-if="licenseInfo.max_docs" class="statistic">
                                <span class="stat-name">Maximum document count</span>
                                <span class="stat-value">{{ licenseInfo.max_docs }}</span>
                            </div>
                            <div v-if="licenseInfo.max_users" class="statistic">
                                <span class="stat-name">Maximum active users</span>
                                <span class="stat-value">{{ licenseInfo.max_users }}</span>
                            </div>
                            <div class="statistic-divider"></div>
                            <div v-if="licenseInfo.days_left" class="statistic">
                                <span class="stat-name">Days left</span>
                                <span class="stat-value">{{ licenseInfo.days_left }}</span>
                            </div>
                            <div v-if="licenseInfo.docs_left" class="statistic">
                                <span class="stat-name">Documents left</span>
                                <span class="stat-value">{{ licenseInfo.docs_left }}</span>
                            </div>
                            <div v-if="licenseInfo.users_left" class="statistic">
                                <span class="stat-name">Users left</span>
                                <span class="stat-value">{{ licenseInfo.users_left }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import getFacetQuery from "../mixins/getFacetQuery";
import getBackendMixin from "../mixins/getBackendUrl";
import MyProfileHistory from "../components/MyProfileHistory.vue";
import DeleteRecordModal from "../components/MyProfile/DeleteRecordModal.vue";
import EnvProvider from "jvjr-docker-env";
import { useCookies } from 'vue3-cookies';
import LastSearchedTerms from "./MyProfile/LastSearchedTerms.vue";
import MostSearchedResults from "./MyProfile/MostSearchedResults.vue";


export default {
    name: "MyProfile",
    props: {
      isVisible: {
        type: Boolean,
        required: true
      }
    },
    computed: {
        items() {
            return this.$store.state.recentExpressions;
        },
        items_searched() {
            return this.$store.state.top10Expressions;
        }
    },
    setup() {
      const { cookies } = useCookies();
      return { cookies };
    },
    data() {
        return {
            suggestList: [],
            savedSearchResult: {},
            term: "",
            activeTab: "tab1",
            licenseInfo: {},
            loadingError: null,
            page: 1,
        };
    },
    components: { MyProfileHistory, DeleteRecordModal, LastSearchedTerms , MostSearchedResults },
    mixins: [getFacetQuery, getBackendMixin],
    methods: {
        closeModal() {
            this.$emit('close-modal'); // Emit an event when the close button is clicked
        },
        loadData(){
            this.loadingError = null;

            // Get license info
            try {
                this.licenseInfo = this.cookies.get('licenseData')
            } catch (error) {
                console.error('Error loading license data:', error);
            }

            let formData = new FormData()
            formData.append('type', 'HISTORY')
            formData.append('rows', '10')

            this.axios.post(EnvProvider.value('API_URL') + '/my-profile',formData).then(response => {
                console.log(response.data.response.docs)
                this.$store.commit('setrecentExpressions', response.data.response.docs);
            }).catch((e) => {
                console.log(e);
                this.loadingError = "Could not fetch recentExpressions"
            });

            console.log('getSearchData')

            formData = new FormData()
            formData.append('type', 'TOP')
            formData.append('rows', '10')

            this.axios.post(EnvProvider.value('API_URL') + '/my-profile',formData).then(response => {
                console.log(response.data.response.docs)
                this.$store.commit('settop10Expressions', response.data.response.docs);
            }).catch((e) => {
                console.log(e);
                this.loadingError = "Could not fetch top10Expressions"
            });
        },
        async doSearch(e) {
            this.term = String(e);
            // if user want to search, we just publish event to search component which processes the request
            this.$router.push({
                name: "Search",
                query: {
                    q: this.term,
                    page: 1,
                },
            });
            this.$root.searchPhrase = this.term;
            const urlHash = "#" + this.$root.allResultTagId;
            const facetQuery = this.getFacetQuery(urlHash);
            // TODO: think of better solution
            this.$store.commit("setPageNumber", 1);
            this.$store.commit("setUriParameters", {
                searchPhrase: this.term,
                searchStart: 0,
                itemsPerPage: this.$root.recordsPerPage,
                facetQuery: facetQuery,
                filterQuery: "",
                idQuery: "",
                tabQuery: "",
                sortQuery: "",
            });
            await this.$store.dispatch("myProfileSearch", {});
            // HOTFIX
            eventBus.$emit("refreshMenu", {});
            eventBus.$emit("refreshFilterBubbles");
        },
        termChanged() {
            if (
                String(this.term).length >= this.$root.numberOfCharactersForSuggester &&
                this.onlySupportedChars()
            ) {
                // suggestions has to be erased
                var tempTerm = this.term;
                var tempSuggestions = [];
                var fabasoftUserRolesProperties = "";
                if (this.$root.useFabasoftRolesExtension) {
                    // if in developement mode, ignore roles extensions by default
                    fabasoftUserRolesProperties =
                        "&gx=" +
                        this.$root.getCookie("gx") +
                        "&px=" +
                        this.$root.getCookie("px") +
                        "&xx=" +
                        this.$root.getCookie("xx") +
                        "&ux=" +
                        this.$root.getCookie("ux");
                }
                axios
                    .get(
                        EnvProvider.value("API_URL") +
                        "/suggest?q=" +
                        tempTerm +
                        fabasoftUserRolesProperties
                    )
                    .then(
                        (response) => {
                            var sugestionResult = response.body.suggest;
                            var mainSug, freeSug;
                            if (sugestionResult.AnalyzingInfixSuggester) {
                                mainSug = suggestionResult.AnalyzingInfixSuggester[tempTerm];
                            }
                            if (sugestionResult.FreeTextSuggester) {
                                freeSug = suggestionResult.FreeTextSuggester[tempTerm];
                            }

                            if (sugestionResult) {
                                var termsSet = new Set();
                                if (mainSug && mainSug.numFound > 0) {
                                    for (var index = 0; index < mainSug.numFound; index++) {
                                        if (
                                            !termsSet.has(
                                                removeDiacritics(
                                                    mainSug.suggestions[index].term.toLowerCase()
                                                )
                                            )
                                        ) {
                                            termsSet.add(
                                                removeDiacritics(
                                                    mainSug.suggestions[index].term.toLowerCase()
                                                )
                                            );
                                            tempSuggestions.push({
                                                title: mainSug.suggestions[index].term,
                                            });
                                        }
                                    }
                                } else if (freeSug && freeSug.numFound > 0) {
                                    for (index = 0; index < freeSug.numFound; index++) {
                                        if (
                                            !termsSet.has(
                                                removeDiacritics(
                                                    freeSug.suggestions[index].term.toLowerCase()
                                                )
                                            )
                                        ) {
                                            termsSet.add(
                                                removeDiacritics(
                                                    freeSug.suggestions[index].term.toLowerCase()
                                                )
                                            );
                                            tempSuggestions.push({
                                                title:
                                                    this.term
                                                        .trim()
                                                        .replace(/\w+[.!?]?$/, "")
                                                        .trim() +
                                                    " " +
                                                    freeSug.suggestions[index].term,
                                            });
                                        }
                                    }
                                }
                            }
                            this.suggestions = tempSuggestions.reverse();
                        },
                        (response) => {
                            eventBus.$emit("errorOccured", {
                                msg: "Chyba textového napovedača:" + response.status,
                            });
                        }
                    );
            }
        },
    },
    watch: {
      isVisible(newValue) {
        if (newValue) {
            this.loadData();
        }
      }
    },
    mounted() {
        console.log("mounted");
        this.loadData();
        
    },
    created() {
        // when searchbox is created, we have to read URL to correctly show query in input box (it's q parameter)
        if (window.location.pathname === "/") {
            // get q param from URL
            var paramName = "q";
            var match = new RegExp("[?&]" + paramName + "=([^&]*)").exec(
                window.location.search
            );
            var q = match && decodeURIComponent(match[1].replace(/\+/g, " "));
            if (q) {
                this.$root.searchPhrase = q;
                this.term = q;
            }
        }
    },
};
</script>

<style lang="scss" scoped src="@/assets/css/MyProfile.scss"></style>
