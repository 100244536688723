const getIdForm = (uriParams, highLightedId) => {

  //const idUri = process.env.API_URL + 
  //'/solr/default/highlighted-detail?hl.q=' +
  //uriParams.idQuery +
  //'&fq=' +
  //uriParams.filterQuery 

  let formQueryData = new FormData()
  formQueryData.append("q",uriParams.searchPhrase)
  formQueryData.append("fq", "id:\"" + highLightedId + "\"")
  return formQueryData
}

export default getIdForm