<template>
    <div class="myProfileSection">
        <div class="myProfileButtons">
            <button @click="getHistoryExpressions()"  class="recentlysearched" :class="{ 'selectedButton': activeComponent === 'lastSearchedTerms'}"> {{ $t("home.lastFoundExpressions.buttonDescription") }} </button>
            <button @click="getSearchData('#MostSearchedResults')"  class="mostsearched" :class="{ 'selectedButton': activeComponent === 'MostSearchedResults'}"> {{ $t("home.top10Expressions.buttonDescription") }}</button>
            <!-- <button @click="getSavedExpressions()" class="saved"> {{ $t("home.savedExpressions.buttonDescription") }} </button> -->
        </div>
        <!-- <myProfileContent v-if="activeComponent === 'MyProfileContent'"/> -->
        <!-- <notFound v-if="activeComponent === 'NotFound'"/> -->
        <mostSearched v-if="activeComponent === 'MostSearchedResults'" />
        <lastSearchedTerms v-if="activeComponent === 'lastSearchedTerms'" />
    </div>
</template>

<script>
import SearchPanel from "../components/Search/SearchPanel.vue"
import MyProfileContent from "../components/Search/MyProfileContent.vue"
import NotFound from "../components/NotFound.vue"
import MostSearchedResults from "../components/MyProfile/MostSearchedResults.vue"
import LastSearchedTerms from "../components/MyProfile/LastSearchedTerms.vue"
import EnvProvider from 'jvjr-docker-env';

import getBackendMixin from "../mixins/getBackendUrl"
import axios from "axios"
import eventBus from "../eventBus"

export default {
    name: "Search",
    inheritAttrs: false,
    components:{
        "myProfileContent": MyProfileContent,
        "searchpanel":SearchPanel,
        "notFound": NotFound,
        "mostSearched": MostSearchedResults,
        "lastSearchedTerms": LastSearchedTerms
    },
    data(){
        return {
            ishidden: false,
            activeComponent: "lastSearchedTerms",
        }
    },
    mixins: [ getBackendMixin ],
    methods:{
        toggle(){
            this.isHidden = !this.ishidden
        },
        getHistoryExpressions () {
            this.activeComponent = 'lastSearchedTerms';
            let formData = new FormData()
            formData.append('global', 'false')
            formData.append('type', 'HISTORY')
            formData.append('rows', '10')

            this.axios.post(EnvProvider.value('API_URL') + '/my-profile',formData).then(response => {
                console.log(response.data.response.docs)
                this.$store.commit('setrecentExpressions', response.data.response.docs);
            })

            return
            axios.get(getBackendMixin.methods.getBackendUrl('identity')).then(response => {
                var username = () => {
                    if (this.$root.useBackendFacade) {
                        return response.body.username;
                    } else {
                        return response.body.identity.username;
                    }
                }
                // delim contains also a string for solr as a way to compensate for a bug in the solr way of accessing user specific data
                var delim = () => {
                    if (this.$root.useBackendFacade) {
                        return "/"
                    } else {
                        return '?fq=user%3A' + encodeURIComponent(username.replace(/:/g, '\\:'));
                    }
                }
                axios.get(this.getBackendUrl('history') + delim).then(
                    response => {
                        if (response.data.response) {
                            this.searchData.recent = response.data.response.docs
                            this.$store.commit('setrecentExpressions', this.searchData.recent);
                        }
                        this.loadingVisibility = true
                        eventBus.$emit('errorOccured', { msg: 'Môj profil sa nepodarilo načítať:' + response.status })
                    },
                )
            })
        },
        getSearchData (selectedTab) {
            this.loadingVisibility = true
            this.activeComponent = 'MostSearchedResults';

            let formData = new FormData()
            formData.append('global', 'false')
            formData.append('type', 'TOP')
            formData.append('rows', '10')

            this.axios.post(EnvProvider.value('API_URL') + '/my-profile',formData).then(response => {
                console.log(response.data.response.docs)
                this.$store.commit('settop10Expressions', response.data.response.docs);
            })
            return
            axios.get(getBackendMixin.methods.getBackendUrl(selectedTab.substring(1))).then(
                response => {
                    this.loadingVisibility = true
                    if (response.data.response) {
                        if (selectedTab === '#MostSearchedResults') {
                            this.searchData.top10 = response.data.response.docs
                            this.$store.commit('settop10Expressions', this.searchData.top10);
                        } else {
                            this.searchData.recent = response.data.response.docs
                            this.$store.commit('setrecentExpressions', this.searchData.recent);
                        }
                    }
                    eventBus.$emit('errorOccured', { msg: 'Môj profil sa nepodarilo načítať:' + response.status })
                },
            )
        },
        // getSavedExpressions () {
        //     this.activeComponent = 'MyProfileContent';
        //     axios.get(getBackendMixin.methods.getBackendUrl('identity')).then(response => {
        //         var username = () => {
        //         if (this.$root.useBackendFacade) {
        //             return response.body.username;
        //         } else {
        //             return response.body.identity.username;
        //         }
        //         }
        //         /* delim contains also a string for solr as a way to compensate for a bug in the solr way of accessing user specific data
        //         had to rewrite the ternary operator into an anonymous function*/
        //         var delim = () => {
        //         if (this.$root.useBackendFacade) {
        //             return "/"
        //         } else {
        //             return '?fq=user%3A' + encodeURIComponent(username.replace(/:/g, '\\:')) + '&rows=';
        //         }
        //         }
        //         axios.get(getBackendMixin.methods.getBackendUrl('getStored') + delim + this.$root.maximumSavedPhrase).then(
        //         response => {
        //             if (response.data.response) {
        //             this.searchData.saved = response.data.response.docs
        //             this.$store.commit('setsavedExpressions', this.searchData.saved)
        //             }
        //             this.loadingVisibility = true
        //             eventBus.$emit('errorOccured', { msg: 'Môj profil sa nepodarilo načítať:' + response.status })         
        //         },
        //                 )
        //             })
        // }
    },
    beforeDestroy () {
        eventBus.$off('callRenderInMyProfile')
    },
    created () {
        this.getSearchData()
        this.getHistoryExpressions()
    }
}

</script>

<style lang="scss" scoped src="@/assets/css/MyProfileHistory.scss"></style>
