export default {
  methods: {
    setLabelForMetadata (metadataIndex) {
      return this.$t(String(this.$root.metadataConfigManager.metadataFields[metadataIndex].label))
    },
    setValueForMetadata (metadataValue, metadataIndex) {
      if (this.$root.metadataConfigManager.metadataFields[metadataIndex].datatype === 'datetime') {
        return this.$root.datetimeFormatter(metadataValue)
      }
      return metadataValue
    }
  }
}
