<template>
    <div class="downloadsigned">
        <img src="../assets/minexLogo1.png"  class="login-img" />
        <div class="content-wrapper">
            <div class="info-text"> 
                <h1>{{ $t("downloadsigned.success") }}</h1>
                <p>{{ $t("downloadsigned.description") }}</p>
            </div>        
            <button @click="downloadSignedDocument" class="login-submit">{{ $t("downloadsigned.buttonText") }}</button>
        </div>
    </div>
</template>

<script>
import { useCookies } from 'vue3-cookies';
import {saveAs} from 'file-saver';
import EnvProvider from 'jvjr-docker-env';

export default {
    name: "DownloadSigned",
    setup() {
        const { cookies } = useCookies();
        return { cookies };
    },
    data() {
        return {
            downloadID: '',
        }
    },
    mounted(){
        this.downloadID = this.$route.query.id;
    },
    methods: {
        async downloadSignedDocument() {
            // AXIOS get here
            const downloadURL = EnvProvider.value('SIGNER_URL') + '/get-signed-document?signid=' + this.downloadID;
            const response = await this.axios({
                                            method: 'get',
                                            url: downloadURL,
                                            responseType: 'blob',
                                        })
                                        .then(response => {
                                            saveAs(response.data, this.downloadID);
                                        });
        }
    },
}
</script>

<style lang="scss" scoped src="@/assets/css/views/DownloadSigned.scss"></style>