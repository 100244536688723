<template>
  <v-pagination
    v-model="page"
    :pages="setMaxPage()"
    :range-size="1"
    active-color="#DEE0E2"
    @update:modelValue="setPageSearch()"
  />
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
    name: "PageCount",
    data (){
        return {
            page: 1
        }
    },
    components: {
        VPagination
    },
    methods: {
        setMaxPage () {
            return Math.ceil(this.$store.state.numFound / this.$root.recordsPerPage)
        },
        setPageSearch() {
            this.$store.commit('setPageNumber', this.page)
            this.$store.dispatch('search', {})
        },
    }
}
</script>
<style lang="scss"  scoped src="@/assets/css/PageCount.scss"></style>