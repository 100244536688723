import facet_counts from "../../../solr/default/search/GET.json"
import { mounted } from '../../main'

const processFacetResults = (facetCounts, facetResults) => {
    var tabsForPopover = {}
    var facetFields = facet_counts.facet_counts.facet_fields //nesting af
    var tempFacetResult = []
    var indexOfTabCriteria = -1
    var indexCounter = 0
    for (var key in facetFields) {
      if (!facetFields[key] || facetFields[key].length === 0 || key === 'noAcl') {
        continue
      }
      var facet = { meta: mounted.$data.metadataFields[key], items: [] }
      tempFacetResult.push(facet)
      for (var m = 0; m < facetFields[key].length; m = m + 2) {
        var item = {}
        // facets for tabs (i. e. type of document)
        if (key === mounted.$data.facetFieldForTab) {
          let metadataObject = mounted.getObjectTypeById(facetFields[key][m])
          item.label = metadataObject.label
          item.categoryHash = metadataObject.hash
          item.value = facetFields[key][m]
          item.Selected = false
        } else {
          // facets for filter in menu
          if (mounted.$data.metadataFields[key]) {
            if (mounted.$data.metadataFields[key].facet && mounted.$data.metadataFields[key].facet.valueFormatter) {
              item.label = mounted.$data.metadataFields[key].facet.valueFormatter(facetFields[key][m])
            } else {
              item.label = facetFields[key][m]
            }
          }
          item.value = facetFields[key][m]
          item.Selected = isFacetSelected(key, item.value, facetResults)
        }
        item.resultCount = facetFields[key][m + 1]
        tempFacetResult[tempFacetResult.length - 1].items.push(item)
        if (item.resultCount === 0 && item.Selected) {
          item.Selected = false
        }
      }
      if (key === mounted.$data.facetFieldForTab) {
        indexOfTabCriteria = indexCounter
      }
      indexCounter++
    }
    var facetRanges = facet_counts.facet_counts.facet_ranges
    for (key in facetRanges) {
      if (!facetRanges[key] || facetRanges[key].counts.length === 0 || key === 'noAcl') {
        continue
      }
      facet = { meta: mounted.$data.metadataFields[key], items: [] }
      tempFacetResult.push(facet)
  
      for (let k = 0; k < facetRanges[key].counts.length; k = k + 2) {
        item = {}
  
        if (mounted.$data.metadataFields[key]) {
          if (mounted.$data.metadataFields[key].facet && mounted.$data.metadataFields[key].facet.valueFormatter) {
            item.label = mounted.$data.metadataFields[key].facet.valueFormatter(facetRanges[key].counts[k])
          } else {
            item.label = facetRanges[key].counts[k]
          }
        }
  
        item.value = facetRanges[key].counts[k]
        item.Selected = isFacetSelected(key, item.value, facetResults)
        item.resultCount = facetRanges[key].counts[k + 1]
        tempFacetResult[tempFacetResult.length - 1].items.push(item)
      }
    }
    // update datetime facets
    tempFacetResult = updateDatetimeFacets(tempFacetResult, mounted.$data)
  
    if (indexOfTabCriteria !== -1) {
      tabsForPopover = tempFacetResult[indexOfTabCriteria]
      tempFacetResult.splice(indexOfTabCriteria, 1)
      // create tab 'All'
      const allResultsTab = createTabForAllResults(tabsForPopover, mounted.$data)
      tabsForPopover.items.splice(0, 0, allResultsTab)
      // remove tabs with zero results
      tabsForPopover.items = tabsForPopover.items.filter(tab => tab.resultCount > 0)
    }
  
    return {
      result: tempFacetResult,
      index: indexOfTabCriteria,
      tabsForPopover: tabsForPopover
    }
  }
  
  const createTabForAllResults = (tabs) => {
    const allResultsTab = {}
    let resultCount = 0
    for (let index in tabs.items) {
      resultCount += parseInt(tabs.items[index].resultCount)
    }
    if (resultCount === 0) {
      // get result count from different facet
    }
    allResultsTab.categoryHash = mounted.$data.allResultTagId
    allResultsTab.label = mounted.getObjectTypeById(mounted.$data.allResultTagId).label
    allResultsTab.value = null
    allResultsTab.resultCount = resultCount
    return allResultsTab
  }
  
  const updateDatetimeFacets = (tempFacetResult) => {
    for (var index in tempFacetResult) {
      if (tempFacetResult[index].meta.datatype === 'datetime') {
        tempFacetResult[index].items = tempFacetResult[index].items.reverse()
        tempFacetResult[index].meta.slider.dataForSlider = []
        for (var item in tempFacetResult[index].items) {
          tempFacetResult[index].meta.slider.dataForSlider.push(tempFacetResult[index].items[item].label)
        }
        tempFacetResult[index].meta.slider.dataForSlider = tempFacetResult[index].meta.slider.dataForSlider.reverse()
        if (tempFacetResult[index].meta.slider.valueForSlider.length === 0) {
          tempFacetResult[index].meta.slider.valueForSlider = []
          tempFacetResult[index].meta.slider.valueForSlider.push(tempFacetResult[index].items[item].label)
          tempFacetResult[index].meta.slider.valueForSlider.push(tempFacetResult[index].items[0].label)
        }
        // TODO: move this to another mutation
        // if (this.$route.query['lastModificationDate-to']) {
        //   tempFacetResult[index].meta.slider.valueForSlider.push(mounted.$data.yearMonthFormmater(this.$route.query['lastModificationDate-to']))
        // }
        // if (this.$route.query['lastModificationDate-from']) {
        //   tempFacetResult[index].meta.slider.valueForSlider.push(mounted.$data.yearMonthFormmater(this.$route.query['lastModificationDate-from']))
        // }
      }
    }
    return tempFacetResult
  }
  
  const isFacetSelected = (name, value, facetRes) => {
    if (facetRes) {
      for (var i = 0; i < facetRes.length; i++) {
        if (facetRes[i].items.length === 0 || facetRes[i].meta.id !== name) {
          continue
        }
  
        for (var k = 0; k < facetRes[i].items.length; k++) {
          if (facetRes[i].items[k].Selected && facetRes[i].items[k].value === value) {
            return true
          }
        }
      }
    }
    return false
  }
  
  export default processFacetResults
  