import { mounted } from '../../main'
import EnvProvider from 'jvjr-docker-env';

const getUris = (uriParams) => {
    let fabasoftUserRolesProperties = ''
    if (mounted.$data.useFabasoftRolesExtension) { // if in development mode, ignore roles extensions by default
      fabasoftUserRolesProperties = '&gx=' +
      mounted.getCookie('gx') +
      '&px=' +
      mounted.getCookie('px') +
      '&xx=' +
      mounted.getCookie('xx') +
      '&ux=' +
      mounted.getCookie('ux')
    }
    const searchUri = EnvProvider.value('API_URL') +
      '/search?q=' +
      uriParams.searchPhrase +
      '&start=' +
      uriParams.searchStart +
      '&rows=' +
      uriParams.itemsPerPage +
      (uriParams.facetQuery ? uriParams.facetQuery : '') +
      (uriParams.filterQuery ? uriParams.filterQuery : '') +
      (uriParams.idQuery ? uriParams.idQuery : '') +
      (uriParams.tabQuery ? uriParams.tabQuery : '') +
      (uriParams.sortQuery ? uriParams.sortQuery : '') +
      process.env.AUTH_OFF +
      fabasoftUserRolesProperties
    const exportUri = EnvProvider.value('API_URL') +
      '/search?q=' +
      uriParams.searchPhrase +
      '&start=0&rows=' +
      mounted.$data.recordsPerExport +
      uriParams.itemsPerPage +
      (uriParams.facetQuery ? uriParams.facetQuery : '') +
      (uriParams.filterQuery ? uriParams.filterQuery : '') +
      (uriParams.idQuery ? uriParams.idQuery : '') +
      (uriParams.tabQuery ? uriParams.tabQuery : '') +
      (uriParams.sortQuery ? uriParams.sortQuery : '') +
      process.env.AUTH_OFF +
      fabasoftUserRolesProperties
    return [searchUri, exportUri]
  }
  
  export default getUris
  