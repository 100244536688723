
<template>
  <div class="headerFlex">
    <div class="logoHeader" v-on:click="prelinkToMinex()" >
      <!-- <img v-if="$route.name !== 'Login'" class="imgLogoHeader" src="../assets/minexLogo.png" alt=""> -->
      <img class="imgLogoHeader" src="../assets/minexLogo.png" alt="">
    </div>
    <div class="profileMenu">

      <!-- help modal window -->
      <a  @click="$root.helpModalShow = !$root.helpModalShow">  {{ $t("header.help") }} </a>
      <teleport to="body"> 
        <div class="modalHelp" v-show="$root.helpModalShow" @click="handleClick">
          <div class="modalViewHelp">
            <helpModal/>
          </div>
        </div>
      </teleport>
      <div>      
        <div class="profileHeader">
          <img class="userIcon" src="../assets/userIcon.png" @click="profileModalShow = !profileModalShow" alt="">
          <a  href="javascript:void(0)"  @click="profileModalShow = !profileModalShow"> {{ $store.state.loggedUser }} </a>
        </div>
      </div>

      <!-- profile modal window -->
      <teleport to="body" v-if="!$root.showOnlyFunctionComponents">
        <div class="modalProfile" v-show="profileModalShow" @click="handleClick">
          <div class="modalViewProfile">
            <myProfile @close-modal="profileModalShow = false" :isVisible="profileModalShow"/>
          </div>
        </div>
      </teleport>

      <div class="p-logout" v-if="$store.state.loggedUser != ''">
      <a href="javascript:void(0)" v-if="$store.state.loggedUser != '' " v-on:click="logoutUser()" >{{ $t("header.logout") }}</a>
      </div>
      <select class="chooseLanguage" v-model="$i18n.locale">
        <option v-for="(lang, i) in locales" :key="`Lang${i}`" :value="lang">   <!--  @click="$root.clearValues()" -->
          <a  href="javascript:void(0)"> {{ lang }} </a>
        </option>
      </select>
    </div>
  </div>
  <transition>
    <info v-if="($store.state.msg.length > 0)" />
  </transition>
 
</template>

<script>

import { useCookies } from 'vue3-cookies'
import HelpModal from "../components/HelpModal.vue"
import Info from './Info.vue'
import MyProfile from "../components/MyProfile.vue"
import EnvProvider from 'jvjr-docker-env';

export default {
  name: "Header",
  components: {
    helpModal: HelpModal,
    myProfile: MyProfile,
    Info
  },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data() {
    return {
      loginVisible: false,
      loggedUser: '',
      products:["EntSearch","GDPRSuite","Anonyma"],
      locales:["sk","cz","de","en","hu"],
      profileModalShow: false
    }
  },
  mounted() {
    let u = this.cookies.get('userName')
    // need to check token on facade
    // if (this.$route.query.userName) {
    //   u = this.$route.query.userName
    //   this.cookies.set('userToken', this.$route.query.userToken, "30min")
    //   this.cookies.set('userName', this.$route.query.userName, "30min")
    // }
    // console.log(u)
    this.loggedUser = (u != null) ? u : ''
    this.$store.state.loggedUser = (u != null) ? u : ''
  },
  watch: {
        '$store.state.msg.length': {
            handler(val,oldVal){
                if( val > oldVal) {
                    setTimeout(() => { this.$store.commit('popMsg'); }, 7000);
                }
            },
            deep: true
        }
  },
  computed: {
    isSearchFormRoute() {
      return this.$route.path === '/';
    }
  },
  methods: {
    handleClick(e) {
      if (e.target.classList.contains("modalHelp")) {
        this.$root.helpModalShow = !this.$root.helpModalShow
      }
      if (e.target.classList.contains("modalProfile")) {
        this.profileModalShow = false; 
      }
    },
    showLogin() {
      this.loginVisible = true;
    },
    closeLogin() {
      this.loginVisible = false;
    },
    logoutUser(){
      console.log('Logging out user');
      this.axios.get(EnvProvider.value('API_AUTH') + this.$root.API_LOGOUT, {withCredentials: true}
                ).then((result) => {
                    console.log(result.data);
                    console.log('Response data: '+ result.data);
                    if (result.status == 200){
                        this.cookies.remove('userName',null,EnvProvider.value('HOST_DOMAIN'));
                        this.cookies.remove('csrfToken');
                        this.$store.commit('setLoggedUser', '');
                        this.$store.commit('pushMsg', { type: 'ok', text: this.$t("authentication.loggedOut") });
                        this.$router.go(0)
                    } else{
                        this.$store.commit('pushMsg', { type: 'error', text: this.$t("authentication.logoutUser") })
                    }
                  }).catch((e) => {
                    console.log(e);
                  });
      console.log('After log out')

    },
    prelinkToMinex() {
      window.location.href = EnvProvider.value('API_AUTH')
    },
  }
}
</script>


<style lang="scss" scoped src="@/assets/css/Header.scss"></style>
<style lang="scss" scoped src="@/assets/css/HelpModal.scss"></style>
