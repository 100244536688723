export default {
    methods: {
      getFacetQuery (urlHash) {
        if (urlHash) {
          urlHash = urlHash.substring(1)
        }
        var facetQuery = ''
        if (this.$root.facetSearch) {
          facetQuery = '&facet=' + this.$root.facetSearch + '&facet.mincount=' + this.$root.facetMinCount
  
          var facetFields = this.$root.getObjectTypeById(this.facetIdByHash(urlHash)).facetedFields
          for (var i = 0; i < facetFields.length; i++) {
            if (facetFields[i].facet && facetFields[i].facet.facetRangeStart && facetFields[i].facet.facetRangeEnd && facetFields[i].facet.facetRangeGap) {
              facetQuery += '&facet.range=%7B!ex=' + facetFields[i].id + '%7D' + facetFields[i].id
              facetQuery += '&facet.range.start=' + facetFields[i].facet.facetRangeStart
              facetQuery += '&facet.range.end=' + facetFields[i].facet.facetRangeEnd
              facetQuery += '&facet.range.gap=' + facetFields[i].facet.facetRangeGap
            } else {
              facetQuery += '&facet.field=%7B!ex=' + facetFields[i].id + '%7D' + facetFields[i].id
            }
          }
          facetQuery += '&facet.field=%7B!ex=' + this.$root.facetFieldForTab + '%7D' + this.$root.facetFieldForTab
        }
        return facetQuery
      },
      getTabQuery (id) {
        var tabQuery = ''
        if (id && id !== this.$root.allResultTagId) {
          tabQuery += '&fq=%7B!tag=' + this.$root.facetFieldForTab + '%7D' + this.$root.facetFieldForTab + ':"' + encodeURIComponent(id) + '"'
        }
        return tabQuery
      },
      facetIdByHash (hash) {
        var id
        for (var i = 0; i < this.$root.metadataConfigManager.metadataObjects.length; i += 1) {
          var metadataObject = this.$root.metadataConfigManager.metadataObjects[i]
          if (metadataObject.hash === hash) {
            id = metadataObject.id
            break
          }
        }
        return id || this.$root.allResultTagId
      }
    }
  }
  