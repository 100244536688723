//import axios from "axios"
import axios from "axios"
import { mounted } from "../../main"

const getSearchResultsFromResponse = (responseData, searchResults, uriParams) => {
  // referred number Alternative + master document as string
  axios.get(String(responseData), response => {
    for (var responseDocsIndex = 0; responseDocsIndex < response.docs.length; responseDocsIndex++) {
      response.docs[responseDocsIndex] = setMasterDocumentAsString(response.docs[responseDocsIndex])
      if (response.docs[responseDocsIndex]['referredNumber'] === undefined && response.docs[responseDocsIndex][mounted.$data.referredNumberAlternative] !== undefined) {
        response.docs[responseDocsIndex]['referredNumber'] = response.docs[responseDocsIndex][mounted.$data.referredNumberAlternative]
        response.docs[responseDocsIndex]['referredLink'] = response.docs[responseDocsIndex][mounted.$data.referredNumberLinkAlternative]
      }
    }
    
    // ACL logic
    if (responseData.fetchAclOnDemand === undefined) {
       mounted.$data.fetchAclOnDemand = false
    } else {
      // noACL.true == 0
      for (var key in responseData.facet_counts.facet_fields.noAcl) {
        if (responseData.facet_counts.facet_fields.noAcl[key] === 'true') {
          if (responseData.facet_counts.facet_fields.noAcl[parseInt(key) + 1] !== 0) {
            mounted.$data.fetchAclOnDemand = true
          }
        }
      }
    }
    if (!responseData.facet_counts) {
      responseData.facet_counts = 0
    }
    // if SOLR send more than ROWS in config
    response.docs = response.docs.slice(0, parseInt(mounted.$data.recordsPerPage))
    if (mounted.$data.fetchAclOnDemand && uriParams.searchStart !== 0) {
      for (i in response.docs) {
        searchResults.push(response.docs[i])
      }
    } else {
      searchResults = response.docs
    }
    
    for (var k = 0; k < searchResults.length; k++) {
      var i = searchResults[k]
      if (responseData.highlighting[i.id] && responseData.highlighting[i.id].content_txt_sk_highlight && responseData.highlighting[i.id].content_txt_sk_highlight.length !== 0) {
        i.content_txt_sk_highlight = responseData.highlighting[i.id].content_txt_sk_highlight[0]
      }
    }
    return searchResults
  })
}
  
const setMasterDocumentAsString = (docs) => {
  if (docs['attrMasterDocumentName']) {
    docs['attrMasterDocumentName'] = docs['attrMasterDocumentName'][0]
  }
  if (docs['masterDocumentName']) {
    docs['masterDocumentName'] = docs['masterDocumentName'][0]
  }
  return docs
}

export default getSearchResultsFromResponse
  