<template>
    <div v-if="store.state.showOverlay" id="overlay">
      <div id="loading">
        <h1>{{ store.state.overlayMessage }}</h1>
      </div>
    </div>
  </template>
  
  <script setup>
  import { useStore } from 'vuex';
  
  const store = useStore();
  const showOverlay = store.state.showOverlay;
  const overlayMessage = store.state.overlayMessage;
  </script>

<style lang="scss" scoped src="../assets/css/LoadingOverlay.scss"/>