import { createApp, h } from 'vue'
import { createI18n } from 'vue-i18n';

import { library, dom } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from "@fortawesome/free-solid-svg-icons"
library.add(fas);
dom.watch();

import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';

import messages from './lang';
import axios from 'axios'
import VueAxios from 'vue-axios'
import { useCookies } from 'vue3-cookies'
import EnvProvider from 'jvjr-docker-env';


const { cookies } = useCookies();

const i18n = createI18n({
    locale: "sk",
    availableLocales: ["sk","cz","en","de","hu"],
    messages,
    globalInjection: true
})

// no cache in response from axios
axios.defaults.headers = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
};

if (EnvProvider.value('ENV') === 'DEV'){
  // FOR DEVELOPMENT ONLY
  axios.defaults.withCredentials = true
};

// Reroute to home if server returns 401 unauthorized
// axios.interceptors.response.use(function (response) {
//   return response
// }, function (error) {
//   console.log(error)
//   var status = 400

//   try {
//     status = error.response.status
//   }catch(err){
//     console.log(err)
//   }

//   if (status === 401) {
//     router.push('/');
//     store.commit('pushMsg', {type: 'error', text: i18n.global.t("authentication.unauthorized")});
//   }
//   return error
// });

// Add CSRF token in header if logged in
axios.interceptors.request.use( function (config) {
    let csrf = cookies.get('csrfToken')
	let user = cookies.get('userName')
    if(EnvProvider.value('LOCAL') === 'true' && EnvProvider.value('ENV') === 'DEV'){   
      config.headers['x-csrf-token'] = 'dev_bypass';
      csrf = 'dev_bypass'
    }
  
    if(user != ''){   
      config.headers['x-csrf-token'] = csrf;
    }
    return config;
  },
  error => {
    console.log(error)
    return Promise.reject(error);
  }
);

/* eslint vue/no-dupe-keys: off */
/* eslint no-dupe-keys: off */
import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp({
    el: '#app',
    store: store,
    data () {
        return {
            FILESYSTEM_DOCUMENT: '/api/file?path=',
            API_LOGIN: '/login',
            API_LOGOUT: '/logout',
            API_AUTH: '/auth',
            API_SEARCH: '/search',
            API_EXPORT: '/export',
            API_SUGGEST: '/suggest',
            API_REPORT: '/api',
            API_HIGHLIGHT: '/highlightedDetail',
            API_URL: 'https://tsearch.anonymizuj.sk',
            FILESYSTEM_URL: 'https://tsearch.anonymizuj.sk',
            REPORT_URL: 'https://tsearch.anonymizuj.sk',
            ANONYMA_URL: 'https://tan.anonymizuj.sk',
            TOKEN_CSRF: 'dev_bypass',
            // API_URL: 'http://localhost:5000',
            // FILESYSTEM_URL: 'http://localhost:8900',
            // REPORT_URL: 'http://localhost:8090',
            signerEnabled: EnvProvider.value('SIGNER_ENABLED') == 'true' ? true : false,
            fetchAclOnDemand: false,
            metadataConfigManager: {},
            metadataObjects: [],
            monthsLabels: ['január', 'február', 'marec', 'apríl', 'máj', 'jún', 'júl', 'august', 'september', 'október', 'november', 'december'],
            metadataFields: {},
            recordsPerPage: 10,
            recordsPerExport: 5,
            facetLimit: '5',
            facetFields: [],  
            facet: true,
            facetSearch: true,
            facetMinCount: 0,
            facetFieldForTab: 'objectTypeId',
            allResultTagId: 'All',
            helpModalShow: false,
            exportModalShow: false,
            profileModalShow: false,
            searchPhrase: "",
            useBackendFacade: false,
            numberOfCharactersForSuggerster: 0,
            useFabasoftRolesExtension: false,
            useDemoRoute: false,
            loggedInUser: "",
            maximumSavedPhrase: 50,
            maximumCountInOneACLSearch: 100,
            showContextSearch: true,
            showOnlyFunctionComponents: false,
            metadataGDPRFields: {
                primaryInformations: {
                    label: 'form.basicInfo',
                    id: 'primaryInformations',
                    showItems: false,
                    items: {
                        personName: { id: 'personName', label: "form.basicInfo_.personName", criteriumLabel: 'Meno osoby', datatype: 'string', multivalued: false, isPersonalInformation: true, searchPhrase: '', helpPhrase: 'helpPhrase.personName', isFiltered: false },
                        birthNumber: { id: 'birthNumber', label: "form.basicInfo_.identificationNumber", criteriumLabel: 'Rodné číslo', datatype: 'string', isPersonalInformation: true, searchPhrase: '', helpPhrase: 'helpPhrase.birthNumber', isFiltered: false, essential: true },
                        birthDate: { id: 'birthDate', label: "form.basicInfo_.dateOfBirth", criteriumLabel: 'Dátum narodenia', valueFormatter: this.datetimeFormatter, datatype: 'datetime', multivalued: false, isPersonalInformation: true, facet: { facetRangeGap: '/MONTH%2B1MONTH', facetRangeStart: 'NOW-20YEAR', facetRangeEnd: 'NOW', valueFormatter: this.yearMonthFormmater }, slider: { valueForSlider: [], dataForSlider: [], sliderVisibility: false }, helpPhrase: 'helpPhrase.birthDate', isFiltered: false },
                        birthPlace: { id: 'birthPlace', label: "form.basicInfo_.birthplace", criteriumLabel: 'Miesto narodenia', datatype: 'string', multivalued: false, isPersonalInformation: true, searchPhrase: '', helpPhrase: 'helpPhrase.birthPlace', isFiltered: false },
                        maritalStatus: { id: 'maritalStatus', label: "form.basicInfo_.familyStatus", criteriumLabel: 'Rodinný stav', datatype: 'string', multivalued: false, isPersonalInformation: true, searchPhrase: '', helpPhrase: 'helpPhrase.maritalStatus', isFiltered: false },
                    }
                },
                identification: {
                    label: "form.identification.idDetails",
                    id: 'identification',
                    showItems: false,
                    items: {
                        idCardNumber: { id: 'idCardNumber', label: "form.identification.idNumber", criteriumLabel: 'Číslo OP', datatype: 'string', multivalued: false, isPersonalInformation: true, searchPhrase: '', helpPhrase: 'helpPhrase.idCardNumber', isFiltered: false, essential: true },
                        passportNumber: { id: 'passportNumber', label: "form.identification.passportNumber", criteriumLabel: 'Číslo pasu', datatype: 'string', multivalued: false, isPersonalInformation: true, searchPhrase: '', helpPhrase: 'helpPhrase.passportNumber', isFiltered: false, essential: true },
                        healthInsurance: { id: 'healthInsurance', label: "form.identification.insuranceCompany", criteriumLabel: 'Zdravotná poisťovňa', datatype: 'string', multivalued: false, isPersonalInformation: true, searchPhrase: '', helpPhrase: 'helpPhrase.healthInsurance', isFiltered: false }
                    }
                },
                bank: {
                    label: "form.bank.bankDetails",
                    id: 'bank',
                    showItems: false,
                    items: {
                        iban: { id: 'iban', label: "form.bank.iban", datatype: 'string', bendLabel: 'IBAN', criteriumLabel: 'IBAN', tagLabel: 'IBAN', multivalued: true, isPersonalInformation: true, searchPhrase: [], helpPhrase: 'helpPhrase.iban', isFiltered: false, essential: true },
                        cardNumber: { id: 'cardNumber', label: "form.bank.paymentCardNumber", criteriumLabel: 'Číslo platobnej karty', tagLabel: 'číslo platobnej karty', datatype: 'string', multivalued: true, isPersonalInformation: true, searchPhrase: [], helpPhrase: 'helpPhrase.cardNumber', isFiltered: false, essential: true }
                    }
                },
                contact: {
                    label: "form.contact.contactInformation",
                    id: 'contact',
                    showItems: false,
                    items: {
                        phoneNumber: { id: 'phoneNumber', label: "form.contact.phoneNumber", criteriumLabel: 'Telefónne číslo', tagLabel: 'telefónne číslo', datatype: 'string', multivalued: true, isPersonalInformation: true, searchPhrase: [], helpPhrase: 'helpPhrase.phonenumber', isFiltered: false, essential: true },
                        email: { id: 'email', label: 'form.contact.email', criteriumLabel: 'Email', tagLabel: 'email', datatype: 'string', multivalued: true, isPersonalInformation: true, searchPhrase: [], helpPhrase: 'helpPhrase.email', isFiltered: false, essential: true }
                    }
                },
                residence: {
                    label: "form.residence.residence",
                    id: 'residence',
                    showItems: false,
                    items: {
                        permanentResidenceMunicipality: { id: 'permanentResidenceMunicipality', label: "form.residence.municipality", criteriumLabel: 'Mesto', datatype: 'string', multivalued: false, isPersonalInformation: true, searchPhrase: '', helpPhrase: 'helpPhrase.permanentResidenceMunicipality', isFiltered: false },
                        permanentResidenceStreet: { id: 'permanentResidenceStreet', label: "form.residence.street", criteriumLabel: "Ulica", datatype: 'string', multivalued: false, isPersonalInformation: true, searchPhrase: '', helpPhrase: 'helpPhrase.permanentResidenceStreet', isFiltered: false },
                        permanentResidenceZipCode: { id: 'permanentResidenceZipCode', label: "form.residence.zipCode", criteriumLabel: 'PSČ', datatype: 'string', multivalued: false, isPersonalInformation: true, searchPhrase: '', helpPhrase: 'helpPhrase.permanentResidenceZipCode', isFiltered: false }
                    }
                },
                job: {
                    label: "form.job.job",
                    id: 'job',
                    showItems: false,
                    items: {
                        companyId: { id: 'companyId', label: "form.job.companyID", criteriumLabel: 'ID zamestnanca', tagLabel: 'ID zamestnanca', datatype: 'number', multivalued: true, isPersonalInformation: true, searchPhrase: [], helpPhrase: 'helpPhrase.companyId', isFiltered: false, essential: true },
                        employerName: { id: 'employerName', label: "form.job.employerName", datatype: 'string', criteriumLabel: 'Názov zamestnanca', multivalued: false, isPersonalInformation: true, searchPhrase: '', helpPhrase: 'helpPhrase.employerName', isFiltered: false },
                        employerCIN: { id: 'employerCIN', label: "form.job.employerCIN", datatype: 'string', criteriumLabel: 'ID zamestnávateľa', multivalued: false, isPersonalInformation: true, searchPhrase: '', helpPhrase: 'helpPhrase.employerCIN', isFiltered: false },
                        employerTIN: { id: 'employerTIN', label: "form.job.employerTIN", criteriumLabel: 'Daňové ID zamestnávateľa', datatype: 'string', multivalued: false, isPersonalInformation: true, searchPhrase: '', helpPhrase: 'helpPhrase.employerTIN', isFiltered: false },
                        jobClassification: { id: 'jobClassification', label: "form.job.jobClasification", criteriumLabel: 'Pracovné zaradenie', datatype: 'string', multivalued: false, isPersonalInformation: true, searchPhrase: '', helpPhrase: 'helpPhrase.jobClassification', isFiltered: false },
                        workplaceMunicipality: { id: 'workplaceMunicipality', label: "form.job.workplaceMunicipality", criteriumLabel: 'Mesto zamestnania', datatype: 'string', multivalued: false, isPersonalInformation: true, searchPhrase: '', helpPhrase: 'helpPhrase.workplaceMunicipality', isFiltered: false },
                        workplaceStreet: { id: 'workplaceStreet', label: "form.job.workplaceStreet", criteriumLabel: 'Ulica zamestnania', datatype: 'string', multivalued: false, isPersonalInformation: true, searchPhrase: '', helpPhrase: 'helpPhrase.workplaceStreet', isFiltered: false },
                        workplaceZipCode: { id: 'workplaceZipCode', label: "form.job.workplaceZipCode", criteriumLabel: 'PSČ zamestnania', datatype: 'string', multivalued: false, isPersonalInformation: true, searchPhrase: '', helpPhrase: 'helpPhrase.workplaceZipCode', isFiltered: false }
                    }
                }
            },
            backendUrlsForFacade: {
                deleteStored: "/profile/stored", //axios.delete, Vue 3
                addStored: "/profile/stored", //axios.post, Vue 3
                getStored: "/profile/stored", //axios.get, Vue 3
                top: "/profile/top", //axios.get, Vue 3
                history: "/profile/history", //axios.get, Vue 3
                identity: "/profile/identity" //axios.get, Vue 3
            },
            backendUrlsForSolr: {
                deleteStored : "/update?commit=true", // PUT, POST depending on contents of req
                addStored : "/update?commit=true", // PUT, POST depending on contents of req
                getStored : "/search/stored",
                top:"/search/top",
                history:"/search/history", 
                identity: "/identity"
            },
            metadataFields: {
                creationDate: { id: 'creationDate', label: "metadataFieldLabels.creationDate", valueFormatter: this.datetimeFormatter, datatype: 'datetime', facet: { facetRangeGap: '/MONTH%2B1MONTH', facetRangeStart: 'NOW-10YEAR', facetRangeEnd: 'NOW', valueFormatter: this.yearMonthFormmater }, slider: { valueForSlider: [], dataForSlider: [], sliderVisibility: false }, helpPhrase: 'Kedy bol dokument vytvorený' },
                lastModificationDate: { id: 'lastModificationDate', label: "metadataFieldLabels.lastModificationDate", valueFormatter: this.datetimeFormatter, datatype: 'datetime', facet: { facetRangeGap: '/MONTH%2B1MONTH', facetRangeStart: 'NOW-10YEAR', facetRangeEnd: 'NOW', valueFormatter: this.yearMonthFormmater }, slider: { valueForSlider: [], dataForSlider: [], sliderVisibility: false }, helpPhrase: 'Kedy bol dokument naposledy upravovaný' },
                lastModifiedBy: { id: 'lastModifiedBy', label: "metadataFieldLabels.lastModifiedBy", datatype: 'string', searchPhrase: '', helpPhrase: 'Kým bol dokument naposledy upravovaný' },
                s_infobox_name: { id: 's_infobox_name', label: "metadataFieldLabels.infoName", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Meno' },
                s_infobox_birthName: { id: 's_infobox_birthName', label: "metadataFieldLabels.infoBirthName", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Rodné meno' },
                s_infobox_personDesc: { id: 's_infobox_personDesc', label: "metadataFieldLabels.infoPersonDescri", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Popis osoby' },
                s_infobox_birthDate: { id: 's_infobox_birthDate', label: "metadataFieldLabels.infoBirthDate", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Dátum narodenia' },
                s_infobox_birthPlace: { id: 's_infobox_birthPlace', label: "metadataFieldLabels.infoBirthPlace", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Miesto narodenia' },
                s_infobox_dateOfDeath: { id: 's_infobox_dateOfDeath', label: "metadataFieldLabels.infoDateOfDeath", searchPhrase: '', helpPhrase: 'Dátum úmrtia' },
                s_infobox_title: { id: 's_infobox_title', label: "metadataFieldLabels.infoTitle", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Názov' },
                s_infobox_desc: { id: 's_infobox_desc', label: "metadataFielLabels.infoDescri", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Popis' },
                s_infobox_epoch: { id: 's_infobox_epoch', label: "metadataFieldLabels.infoEpoch", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Epocha' },
                s_infobox_type: { id: 's_infobox_type', label: "metadataFieldLabels.infoType", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Typ' },
                s_infobox_class: { id: 's_infobox_class', label: "metadataFieldLabels.infoClass", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Trieda' },
                s_infobox_distance: { id: 's_infobox_distance', label: "metadataFieldLabels.infoDistance", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Vzdialenosť' },
                s_infobox_age: { id: 's_infobox_age', label: "metadataFieldLabels.infoAge", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Vek' },
                s_infobox_average: { id: 's_infobox_average', label: "metadataFieldLabels.infoAverage", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Priemer' },
                s_infobox_weight: { id: 's_infobox_weight', label: "metadataFieldLabels.infoWeight", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Hmotnosť' },
                s_infobox_part: { id: 's_infobox_part', label: "metadataFieldLabels.infoPart", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Časť čoho' },
                s_infobox_sign: { id: 's_infobox_sign', label: "metadataFieldLabels.infoSign", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Znak' },
                s_infobox_county: { id: 's_infobox_county', label: "metadataFieldLabels.infoCountry", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Kraj' },
                s_infobox_district: { id: 's_infobox_district', label: "metadataFieldLabels.infoDistrict", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Okres' },
                s_infobox_region: { id: 's_infobox_region', label: "metadataFieldLabels.infoRegion", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Región' },
                s_infobox_area: { id: 's_infobox_area', label: "metadataFielLabels.infoArea", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Rozloha' },
                s_infobox_population: { id: 's_infobox_population', label: "metadataFieldLabels.infoPopulation", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Počet obyvateľov' },
                s_infobox_zip: { id: 's_infobox_zip', label: "metadataFieldLabels.infoZip", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'PSČ' },
                s_infobox_ecv: { id: 's_infobox_ecv', label: "metadataFieldLabels.infoEcv", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'EČV' },
                s_infobox_headline: { id: 's_infobox_headline', label: "metadataFieldLabels.infoHeadline", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Titulok' },
                s_infobox_threatLevel: { id: 's_infobox_threatLevel', label: "metadataFieldLabels.infoThreatLevel", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Stupeň ohrozenia' },
                s_infobox_empireSk: { id: 's_infobox_empireSk', label: "metadataFieldLabels.infoEmpireSK", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Ríška po slovensky' },
                s_infobox_empireLat: { id: 's_infobox_empireLat', label: "metadataFieldLabels.infoEmpireLat", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Ríša po latinsky' },
                s_infobox_performer: { id: 's_infobox_performer', label: "metadataFieldLabels.infoPerformer", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Interpret' },
                s_infobox_genre: { id: 's_infobox_genre', label: "metadataFieldLabels.infoGenre", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Žáner' },
                s_infobox_length: { id: 's_infobox_length', label: "metadataFieldLabels.infoLength", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Dĺžka' },
                s_infobox_releaseDate: { id: 's_infobox_releaseDate', label: "metadataFieldLabels.infoReleaseDate", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Dátum vydania' },
                s_infobox_publisher: { id: 's_infobox_publisher', label: "metadataFieldLabels.infoPublisher", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Vydavateľ' },
                s_infobox_producedBy: { id: 's_infobox_producedBy', label: "metadataFieldLabels.infoProducedBy", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Producent' },
                s_infobox_composer: { id: 's_infobox_composer', label: "metadataFieldLabels.infoComposer", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Skladateľ' },
                s_infobox_origName: { id: 's_infobox_origName', label: "metadataFieldLabels.infoOrigName", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Originálny názov' },
                s_infobox_introductionDate: { id: 's_infobox_introductionDate', label: "metadataFieldLabels.infoIntroductionDate", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Dátum uvedenia' },
                s_infobox_budget: { id: 's_infobox_budget', label: "metadataFieldLabels.infoBudget", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Rozpočet' },
                s_infobox_earnings: { id: 's_infobox_earnings', label: "metadataFieldLabels.infoEarnings", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Zárobok' },
                s_infobox_directedBy: { id: 's_infobox_directedBy', label: "metadataFieldLabels.infoDirectedBy", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Réžia' },
                s_infobox_screenplay: { id: 's_infobox_screenplay', label: "metadataFieldLabels.infoScreenPlay", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Scenár' },
                s_infobox_companyName: { id: 's_infobox_companyName', label: "metadataFieldLabels.infoCompanyName", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Názov spoločnosti' },
                s_infobox_legalForm: { id: 's_infobox_legalForm', label: "metadataFieldLabels.infoLegalForm", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Právna forma' },
                s_infobox_companyId: { id: 's_infobox_companyId', label: "metadataFieldLabels.infoCompanyId", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'IČO' },
                s_infobox_sector: { id: 's_infobox_sector', label: "metadataFieldLabels.infoSector", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Odvetvie' },
                s_infobox_established: { id: 's_infobox_established', label: "metadataFieldLabels.infoEstablished", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Založená' },
                s_infobox_founder: { id: 's_infobox_founder', label: "metadataFieldLabels.infoFounder", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Zakladateľ' },
                s_infobox_residence: { id: 's_infobox_residence', label: "metadataFieldLabels.infoResidence", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Sídlo' },
                s_infobox_turnover: { id: 's_infobox_turnover', label: "metadataFieldLabels.infoTurnover", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Obrat' },
                s_infobox_textSk: { id: 's_infobox_textSk', label: "metadataFieldLabels.infoTextSk", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Text SK' },
                s_infobox_textEn: { id: 's_infobox_textEn', label: "metadataFieldLabels.infoTextEn", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Text EN' },
                s_infobox_story: { id: 's_infobox_story', label: "metadataFieldLabels.infoStory", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Námet' },
                s_infobox_cast: { id: 's_infobox_cast', label: "metadataFieldLabels.infoCast", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Obsadenie' },
                s_infobox_seriesNum: { id: 's_infobox_seriesNum', label: "metadataFieldLabels.infoSeriesNum", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Počet sérií' },
                s_infobox_producer: { id: 's_infobox_producer', label: "metadataFieldLabels.infoProducer", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Výrobca' },
                s_infobox_parentCompany: { id: 's_infobox_parentCompany', label: "metadataFieldLabels.infoParentCompany", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Materská spoločnosť' },
                s_infobox_countryOfOrigin: { id: 's_infobox_countryOfOrigin', label: "metadataFieldLabels.infoCountryOfOrigin", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Krajina pôvodu' },
                s_infobox_bodyType: { id: 's_infobox_bodyType', label: "metadataFieldLabels.infoBodyType", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Typ karosérie' },
                s_infobox_platform: { id: 's_infobox_platform', label: "metadataFieldLabels.infoPlatform", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Platforma' },
                s_infobox_engine: { id: 's_infobox_engine', label: "metadataFieldLabels.infoEngine", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Motor' },
                s_infobox_maxSpeed: { id: 's_infobox_maxSpeed', label: "metadataFieldLabels.infoMaxSpeed", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Maximálna rýchlosť' },
                s_infobox_height: { id: 's_infobox_height', label: "metadataFieldLabels.infoHeight", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Výška' },
                s_infobox_width: { id: 's_infobox_width', label: "metadataFieldLabels.infoWidth", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Šírka' },
                s_infobox_author: { id: 's_infobox_author', label: "metadataFieldLabels.infoAuthor", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Autor' },
                s_infobox_coAuthor: { id: 's_infobox_coAuthor', label: "metadataFieldLabels.infoCoAuthor", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Spoluautor' },
                s_infobox_issueCountry: { id: 's_infobox_issueCountry', label: "metadataFieldLabels.infoIssueCountry", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Krajina vydania' },
                s_infobox_isbn: { id: 's_infobox_isbn', label: "metadataFieldLabels.infoISBN", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'ISBN' },
                s_infobox_pageCount: { id: 's_infobox_pageCount', label: "metadataFieldLabels.infoPageCount", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Počet strán' },
                s_infobox_instrument: { id: 's_infobox_instrument', label: "metadataFieldLabels.infoInstrument", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Nástroj' },
                s_infobox_album: { id: 's_infobox_album', label: "metadataFieldLabels.infoAlbum", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Album' },
                s_infobox_revenues: { id: 's_infobox_revenues', label: "metadataFieldLabels.infoRevenues", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Výnosy' },
                s_infobox_profit: { id: 's_infobox_profit', label: "metadataFieldLabels.infoProfit", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Zisk' },
                s_infobox_industry: { id: 's_infobox_industry', label: "metadataFieldLabels.infoIndustry", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Priemysel' },
                s_infobox_owner: { id: 's_infobox_owner', label: "metadataFieldLabels.infoOwner", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Vlastník' },
                s_infobox_slovak: { id: 's_infobox_slovak', label: "metadataFieldLabels.infoSlovak", datatype: 'string', valueFormatter: this.multivaluedFieldFormatter, searchPhrase: '', helpPhrase: 'Slovensky' }
            },
        }
    },
    methods: {
        tabLabelHash (label) {
            return label.toLowerCase().replace(/ /g, '-')
        },
        setMonthLabels () {
             return [this.$t("leftSideMenu.months.january"), this.$t("leftSideMenu.months.february"), this.$t("leftSideMenu.months.march"), this.$t("leftSideMenu.months.april"), this.$t("leftSideMenu.months.may"), this.$t("leftSideMenu.months.june"), this.$t("leftSideMenu.months.july"), this.$t("leftSideMenu.months.august"), this.$t("leftSideMenu.months.september"), this.$t("leftSideMenu.months.october"), this.$t("leftSideMenu.months.november"),this.$t("leftSideMenu.months.december")]
        },
        yearMonthFormmater (value) {
            var dates = value.substring(0, 7).split('-')
            return (dates[1][0] === '0' ? this.monthsLabels[parseInt(dates[1][1]) - 1] : this.monthsLabels[parseInt(dates[1]) - 1]) + ' ' + dates[0]
        },  
        datetimeFormatter (value) {
            var date = new Date(value)
            return date.getDate() + '. ' + this.monthsLabels[date.getMonth()] + ' ' + date.getFullYear() + ' o ' + (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
        },
        getObjectTypeById (key) {
            var object = {}
            this.metadataObjects.forEach(function(e) {
              if (e.id === key) {
                object = e
                return
              }
            })
            return object
        },
        getUrlParameterByName (name, url) {
            if (!url) url = window.location.href
            name = name.replace('/[[]]/g', '\\$&')
            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
            var results = regex.exec(url)
            if (!results) return null
            if (!results[2]) return ''
            return decodeURIComponent(results[2].replace(/\+/g, ' '))
        },  
        tabLabelHash (label) {
            return label.toLowerCase().replace(/ /g, '-')
        },
        sourceIdValueFormatter (value) {
            switch (value) {
              case 'fabasoft':
                return 'Fabasoft'
              case 'sharepoint':
                return 'Sharepoint'
              case 'disk':
                return 'Filesystem'
              default:
                return value
            }
        },
        getCookie (cname) {
            var name = cname + '='
            var decodedCookie = decodeURIComponent(document.cookie)
            var ca = decodedCookie.split(';')
            for (var i = 0; i < ca.length; i++) {
              var c = ca[i]
              while (c.charAt(0) === ' ') {
                c = c.substring(1)
              }
              if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length)
              }
            }
            return ''
        },      
        setMetadataObjects: function () {
            this.metadataObjects = []
            this.metadataObjects.push({
                label: "metadataObjectLabels.xlsDocument",
                id: 'COOMSOFFICE@1.1:ExcelObject',
                hash: this.tabLabelHash('XLS dokument'),
                facetedFields: [this.$root.metadataFields['sourceId'], this.$root.metadataFields['createdBy'], this.$root.metadataFields['creationDate']],
                searchRecordMetadataFields: [this.$root.metadataFields['sourceId'], this.$root.metadataFields['createdBy'], this.$root.metadataFields['creationDate']],
                exportedFields: [this.$root.metadataFields['sourceId'], this.$root.metadataFields['createdBy'], this.$root.metadataFields['creationDate']]
            })
            this.metadataObjects.push({
                label: "metadataObjectLabels.wordDocument",
                id: 'COOMSOFFICE@1.1:WinWordObject',
                hash: this.tabLabelHash('Word dokument'),
                facetedFields: [this.$root.metadataFields['sourceId'], this.$root.metadataFields['createdBy'], this.$root.metadataFields['creationDate']],
                searchRecordMetadataFields: [this.$root.metadataFields['sourceId'], this.$root.metadataFields['createdBy'], this.$root.metadataFields['creationDate']],
                exportedFields: [this.$root.metadataFields['sourceId'], this.$root.metadataFields['createdBy'], this.$root.metadataFields['creationDate']]
            })
            this.metadataObjects.push({
                label: "metadataObjectLabels.pdfDocument",
                id: 'FSCACROBAT@1.1:PDFObject',
                hash: this.tabLabelHash('PDF dokument'),
                facetedFields: [this.$root.metadataFields['sourceId'], this.$root.metadataFields['createdBy'], this.$root.metadataFields['creationDate']],
                searchRecordMetadataFields: [this.$root.metadataFields['sourceId'], this.$root.metadataFields['createdBy'], this.$root.metadataFields['creationDate']],
                exportedFields: [this.$root.metadataFields['sourceId'], this.$root.metadataFields['createdBy'], this.$root.metadataFields['creationDate']]
            })
            this.metadataObjects.push({
                label: "metadataObjectLabels.txtDocument",
                id: 'TXTDocument',
                hash: this.tabLabelHash('Textový dokument'),
                facetedFields: [this.$root.metadataFields['sourceId'], this.$root.metadataFields['createdBy'], this.$root.metadataFields['creationDate']],
                searchRecordMetadataFields: [this.$root.metadataFields['sourceId'], this.$root.metadataFields['createdBy'], this.$root.metadataFields['creationDate']],
                exportedFields: [this.$root.metadataFields['sourceId'], this.$root.metadataFields['createdBy'], this.$root.metadataFields['creationDate']]
            })
            this.metadataObjects.push({
                label: "metadataObjectLabels.all",
                id: this.allResultTagId,
                hash: this.allResultTagId,
                facetedFields: [this.$root.metadataFields['sourceId'], this.$root.metadataFields['createdBy'], this.$root.metadataFields['creationDate']],
                searchRecordMetadataFields: [this.$root.metadataFields['sourceId'], this.$root.metadataFields['createdBy'], this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate']],
                exportedFields: [this.$root.metadataFields['sourceId'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['createdBy'], this.$root.metadataFields['creationDate']]
            })
            this.metadataObjects.push({
                label: 'Osobnosť',
                id: 'osobnost',
                hash: this.tabLabelHash('Osobnosť'),
                facetedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_name'], this.$root.metadataFields['s_infobox_birthName'], this.$root.metadataFields['s_infobox_birthDate'], this.$root.metadataFields['s_infobox_birthPlace'], this.$root.metadataFields['s_infobox_dateOfDeath']],
                searchRecordMetadataFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_name'], this.$root.metadataFields['s_infobox_birthName'], this.$root.metadataFields['s_infobox_birthDate'], this.$root.metadataFields['s_infobox_birthPlace'], this.$root.metadataFields['s_infobox_dateOfDeath']],
                exportedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_name'], this.$root.metadataFields['s_infobox_birthName'], this.$root.metadataFields['s_infobox_personDesc'], this.$root.metadataFields['s_infobox_birthDate'], this.$root.metadataFields['s_infobox_birthPlace'], this.$root.metadataFields['s_infobox_dateOfDeath']]
            })
            this.metadataObjects.push({
                label: 'Deepsky',
                id: 'deepsky',
                hash: this.tabLabelHash('Deepsky'),
                facetedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_title'], this.$root.metadataFields['s_infobox_epoch'], this.$root.metadataFields['s_infobox_type'], this.$root.metadataFields['s_infobox_class'], this.$root.metadataFields['s_infobox_distance'], this.$root.metadataFields['s_infobox_age'], this.$root.metadataFields['s_infobox_average'], this.$root.metadataFields['s_infobox_weight']],
                searchRecordMetadataFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_title'], this.$root.metadataFields['s_infobox_epoch'], this.$root.metadataFields['s_infobox_type'], this.$root.metadataFields['s_infobox_class'], this.$root.metadataFields['s_infobox_distance'], this.$root.metadataFields['s_infobox_age'], this.$root.metadataFields['s_infobox_average'], this.$root.metadataFields['s_infobox_weight']],
                exportedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_title'], this.$root.metadataFields['s_infobox_desc'], this.$root.metadataFields['s_infobox_epoch'], this.$root.metadataFields['s_infobox_type'], this.$root.metadataFields['s_infobox_class'], this.$root.metadataFields['s_infobox_distance'], this.$root.metadataFields['s_infobox_age'], this.$root.metadataFields['s_infobox_average'], this.$root.metadataFields['s_infobox_weight']]
            })
            this.metadataObjects.push({
                label: 'Slovenská obec',
                id: 'slovenska-obec',
                hash: this.tabLabelHash('Slovenská obec'),
                facetedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_type'], this.$root.metadataFields['s_infobox_part'], this.$root.metadataFields['s_infobox_title'], this.$root.metadataFields['s_infobox_sign'], this.$root.metadataFields['s_infobox_county'], this.$root.metadataFields['s_infobox_district'], this.$root.metadataFields['s_infobox_region'], this.$root.metadataFields['s_infobox_area'], this.$root.metadataFields['s_infobox_population'], this.$root.metadataFields['s_infobox_zip'], this.$root.metadataFields['s_infobox_ecv']],
                searchRecordMetadataFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_type'], this.$root.metadataFields['s_infobox_part'], this.$root.metadataFields['s_infobox_title'], this.$root.metadataFields['s_infobox_sign'], this.$root.metadataFields['s_infobox_county'], this.$root.metadataFields['s_infobox_district'], this.$root.metadataFields['s_infobox_region'], this.$root.metadataFields['s_infobox_area'], this.$root.metadataFields['s_infobox_population'], this.$root.metadataFields['s_infobox_zip'], this.$root.metadataFields['s_infobox_ecv']],
                exportedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_type'], this.$root.metadataFields['s_infobox_part'], this.$root.metadataFields['s_infobox_title'], this.$root.metadataFields['s_infobox_sign'], this.$root.metadataFields['s_infobox_county'], this.$root.metadataFields['s_infobox_district'], this.$root.metadataFields['s_infobox_region'], this.$root.metadataFields['s_infobox_area'], this.$root.metadataFields['s_infobox_population'], this.$root.metadataFields['s_infobox_zip'], this.$root.metadataFields['s_infobox_ecv']]
            })
            this.metadataObjects.push({
                label: 'Živočíchy',
                id: 'zivocichy',
                hash: this.tabLabelHash('Živočíchy'),
                facetedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_title'], this.$root.metadataFields['s_infobox_headline'], this.$root.metadataFields['s_infobox_threatLevel'], this.$root.metadataFields['s_infobox_empireSk'], this.$root.metadataFields['s_infobox_empireLat']],
                searchRecordMetadataFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_title'], this.$root.metadataFields['s_infobox_headline'], this.$root.metadataFields['s_infobox_threatLevel'], this.$root.metadataFields['s_infobox_empireSk'], this.$root.metadataFields['s_infobox_empireLat']],
                exportedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_title'], this.$root.metadataFields['s_infobox_headline'], this.$root.metadataFields['s_infobox_threatLevel'], this.$root.metadataFields['s_infobox_empireSk'], this.$root.metadataFields['s_infobox_empireLat']]
            })
            this.metadataObjects.push({
                label: 'Album',
                id: 'album',
                hash: this.tabLabelHash('Album'),
                facetedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_performer'], this.$root.metadataFields['s_infobox_title'], this.$root.metadataFields['s_infobox_type'], this.$root.metadataFields['s_infobox_genre'], this.$root.metadataFields['s_infobox_length'], this.$root.metadataFields['s_infobox_releaseDate'], this.$root.metadataFields['s_infobox_publisher'], this.$root.metadataFields['s_infobox_producedBy'], this.$root.metadataFields['s_infobox_composer']],
                searchRecordMetadataFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_performer'], this.$root.metadataFields['s_infobox_title'], this.$root.metadataFields['s_infobox_type'], this.$root.metadataFields['s_infobox_genre'], this.$root.metadataFields['s_infobox_length'], this.$root.metadataFields['s_infobox_releaseDate'], this.$root.metadataFields['s_infobox_publisher'], this.$root.metadataFields['s_infobox_producedBy'], this.$root.metadataFields['s_infobox_composer']],
                exportedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_performer'], this.$root.metadataFields['s_infobox_title'], this.$root.metadataFields['s_infobox_type'], this.$root.metadataFields['s_infobox_genre'], this.$root.metadataFields['s_infobox_length'], this.$root.metadataFields['s_infobox_releaseDate'], this.$root.metadataFields['s_infobox_desc'], this.$root.metadataFields['s_infobox_publisher'], this.$root.metadataFields['s_infobox_producedBy'], this.$root.metadataFields['s_infobox_composer']]
            })
            this.metadataObjects.push({
                label: 'Umelec',
                id: 'umelec',
                hash: this.tabLabelHash('Umelec'),
                facetedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_name'], this.$root.metadataFields['s_infobox_birthName'], this.$root.metadataFields['s_infobox_birthDate'], this.$root.metadataFields['s_infobox_birthPlace'], this.$root.metadataFields['s_infobox_dateOfDeath']],
                searchRecordMetadataFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_name'], this.$root.metadataFields['s_infobox_birthName'], this.$root.metadataFields['s_infobox_birthDate'], this.$root.metadataFields['s_infobox_birthPlace'], this.$root.metadataFields['s_infobox_dateOfDeath']],
                exportedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_name'], this.$root.metadataFields['s_infobox_birthName'], this.$root.metadataFields['s_infobox_personDesc'], this.$root.metadataFields['s_infobox_birthDate'], this.$root.metadataFields['s_infobox_birthPlace'], this.$root.metadataFields['s_infobox_dateOfDeath']]
            })
            this.metadataObjects.push({
                label: 'Športovec',
                id: 'sportovec',
                hash: this.tabLabelHash('Športovec'),
                facetedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_name'], this.$root.metadataFields['s_infobox_birthName'], this.$root.metadataFields['s_infobox_birthDate'], this.$root.metadataFields['s_infobox_birthPlace'], this.$root.metadataFields['s_infobox_dateOfDeath']],
                searchRecordMetadataFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_name'], this.$root.metadataFields['s_infobox_birthName'], this.$root.metadataFields['s_infobox_birthDate'], this.$root.metadataFields['s_infobox_birthPlace'], this.$root.metadataFields['s_infobox_dateOfDeath']],
                exportedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_name'], this.$root.metadataFields['s_infobox_birthName'], this.$root.metadataFields['s_infobox_personDesc'], this.$root.metadataFields['s_infobox_birthDate'], this.$root.metadataFields['s_infobox_birthPlace'], this.$root.metadataFields['s_infobox_dateOfDeath']]
            })
            this.metadataObjects.push({
                label: 'Skladba',
                id: 'skladba',
                hash: this.tabLabelHash('Skladba'),
                facetedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_title'], this.$root.metadataFields['s_infobox_performer'], this.$root.metadataFields['s_infobox_album'], this.$root.metadataFields['s_infobox_genre'], this.$root.metadataFields['s_infobox_length'], this.$root.metadataFields['s_infobox_releaseDate'], this.$root.metadataFields['s_infobox_publisher'], this.$root.metadataFields['s_infobox_producedBy'], this.$root.metadataFields['s_infobox_composer']],
                searchRecordMetadataFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_title'], this.$root.metadataFields['s_infobox_performer'], this.$root.metadataFields['s_infobox_album'], this.$root.metadataFields['s_infobox_genre'], this.$root.metadataFields['s_infobox_length'], this.$root.metadataFields['s_infobox_releaseDate'], this.$root.metadataFields['s_infobox_publisher'], this.$root.metadataFields['s_infobox_producedBy'], this.$root.metadataFields['s_infobox_composer']],
                exportedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_title'], this.$root.metadataFields['s_infobox_performer'], this.$root.metadataFields['s_infobox_album'], this.$root.metadataFields['s_infobox_desc'], this.$root.metadataFields['s_infobox_genre'], this.$root.metadataFields['s_infobox_length'], this.$root.metadataFields['s_infobox_releaseDate'], this.$root.metadataFields['s_infobox_publisher'], this.$root.metadataFields['s_infobox_producedBy'], this.$root.metadataFields['s_infobox_composer']]
            })
            this.metadataObjects.push({
                label: 'Film',
                id: 'film',
                hash: this.tabLabelHash('Film'),
                facetedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_title'], this.$root.metadataFields['s_infobox_performer'], this.$root.metadataFields['s_infobox_album'], this.$root.metadataFields['s_infobox_genre'], this.$root.metadataFields['s_infobox_length'], this.$root.metadataFields['s_infobox_releaseDate'], this.$root.metadataFields['s_infobox_publisher'], this.$root.metadataFields['s_infobox_producedBy'], this.$root.metadataFields['s_infobox_composer']],
                searchRecordMetadataFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_title'], this.$root.metadataFields['s_infobox_performer'], this.$root.metadataFields['s_infobox_album'], this.$root.metadataFields['s_infobox_genre'], this.$root.metadataFields['s_infobox_length'], this.$root.metadataFields['s_infobox_releaseDate'], this.$root.metadataFields['s_infobox_publisher'], this.$root.metadataFields['s_infobox_producedBy'], this.$root.metadataFields['s_infobox_composer']],
                exportedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_title'], this.$root.metadataFields['s_infobox_performer'], this.$root.metadataFields['s_infobox_album'], this.$root.metadataFields['s_infobox_desc'], this.$root.metadataFields['s_infobox_genre'], this.$root.metadataFields['s_infobox_length'], this.$root.metadataFields['s_infobox_releaseDate'], this.$root.metadataFields['s_infobox_publisher'], this.$root.metadataFields['s_infobox_producedBy'], this.$root.metadataFields['s_infobox_composer']]
            })
            this.metadataObjects.push({
                label: 'Spoločnosť',
                id: 'spolocnost',
                hash: this.tabLabelHash('Spoločnosť'),
                facetedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_companyName'], this.$root.metadataFields['s_infobox_legalForm'], this.$root.metadataFields['s_infobox_companyId'], this.$root.metadataFields['s_infobox_sector'], this.$root.metadataFields['s_infobox_established'], this.$root.metadataFields['s_infobox_founder'], this.$root.metadataFields['s_infobox_residence'], this.$root.metadataFields['s_infobox_turnover'], this.$root.metadataFields['s_infobox_revenues'], this.$root.metadataFields['s_infobox_profit'], this.$root.metadataFields['s_infobox_industry'], this.$root.metadataFields['s_infobox_owner']],
                searchRecordMetadataFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_companyName'], this.$root.metadataFields['s_infobox_legalForm'], this.$root.metadataFields['s_infobox_companyId'], this.$root.metadataFields['s_infobox_sector'], this.$root.metadataFields['s_infobox_established'], this.$root.metadataFields['s_infobox_founder'], this.$root.metadataFields['s_infobox_residence'], this.$root.metadataFields['s_infobox_turnover'], this.$root.metadataFields['s_infobox_revenues'], this.$root.metadataFields['s_infobox_profit'], this.$root.metadataFields['s_infobox_industry'], this.$root.metadataFields['s_infobox_owner']],
                exportedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_companyName'], this.$root.metadataFields['s_infobox_desc'], this.$root.metadataFields['s_infobox_legalForm'], this.$root.metadataFields['s_infobox_companyId'], this.$root.metadataFields['s_infobox_sector'], this.$root.metadataFields['s_infobox_established'], this.$root.metadataFields['s_infobox_founder'], this.$root.metadataFields['s_infobox_residence'], this.$root.metadataFields['s_infobox_turnover'], this.$root.metadataFields['s_infobox_revenues'], this.$root.metadataFields['s_infobox_profit'], this.$root.metadataFields['s_infobox_industry'], this.$root.metadataFields['s_infobox_owner']]
            })
            this.metadataObjects.push({
                label: 'Televízny seriál',
                id: 'televizny-serial',
                hash: this.tabLabelHash('Televízny seriál'),
                facetedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_textSk'], this.$root.metadataFields['s_infobox_textEn'], this.$root.metadataFields['s_infobox_genre'], this.$root.metadataFields['s_infobox_story'], this.$root.metadataFields['s_infobox_screenplay'], this.$root.metadataFields['s_infobox_directedBy'], this.$root.metadataFields['s_infobox_cast'], this.$root.metadataFields['s_infobox_slovak'], this.$root.metadataFields['s_infobox_seriesNum']],
                searchRecordMetadataFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_textSk'], this.$root.metadataFields['s_infobox_textEn'], this.$root.metadataFields['s_infobox_genre'], this.$root.metadataFields['s_infobox_story'], this.$root.metadataFields['s_infobox_screenplay'], this.$root.metadataFields['s_infobox_directedBy'], this.$root.metadataFields['s_infobox_cast'], this.$root.metadataFields['s_infobox_slovak'], this.$root.metadataFields['s_infobox_seriesNum']],
                exportedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_textSk'], this.$root.metadataFields['s_infobox_textEn'], this.$root.metadataFields['s_infobox_genre'], this.$root.metadataFields['s_infobox_story'], this.$root.metadataFields['s_infobox_screenplay'], this.$root.metadataFields['s_infobox_directedBy'], this.$root.metadataFields['s_infobox_cast'], this.$root.metadataFields['s_infobox_slovak'], this.$root.metadataFields['s_infobox_seriesNum']]
            })
            this.metadataObjects.push({
                label: 'Rastliny',
                id: 'rastliny',
                hash: this.tabLabelHash('Rastliny'),
                facetedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_title'], this.$root.metadataFields['s_infobox_headline'], this.$root.metadataFields['s_infobox_threatLevel'], this.$root.metadataFields['s_infobox_empireSk'], this.$root.metadataFields['s_infobox_empireLat']],
                searchRecordMetadataFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_title'], this.$root.metadataFields['s_infobox_headline'], this.$root.metadataFields['s_infobox_threatLevel'], this.$root.metadataFields['s_infobox_empireSk'], this.$root.metadataFields['s_infobox_empireLat']],
                exportedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_title'], this.$root.metadataFields['s_infobox_headline'], this.$root.metadataFields['s_infobox_threatLevel'], this.$root.metadataFields['s_infobox_empireSk'], this.$root.metadataFields['s_infobox_empireLat']]
            })
            this.metadataObjects.push({
                label: 'Automobil',
                id: 'automobil',
                hash: this.tabLabelHash('Automobil'),
                facetedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_title'], this.$root.metadataFields['s_infobox_producer'], this.$root.metadataFields['s_infobox_parentCompany'], this.$root.metadataFields['s_infobox_countryOfOrigin'], this.$root.metadataFields['s_infobox_class'], this.$root.metadataFields['s_infobox_bodyType'], this.$root.metadataFields['s_infobox_platform'], this.$root.metadataFields['s_infobox_engine'], this.$root.metadataFields['s_infobox_maxSpeed'], this.$root.metadataFields['s_infobox_height'], this.$root.metadataFields['s_infobox_width'], this.$root.metadataFields['s_infobox_length'], this.$root.metadataFields['s_infobox_weight']],
                searchRecordMetadataFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_title'], this.$root.metadataFields['s_infobox_producer'], this.$root.metadataFields['s_infobox_parentCompany'], this.$root.metadataFields['s_infobox_countryOfOrigin'], this.$root.metadataFields['s_infobox_class'], this.$root.metadataFields['s_infobox_bodyType'], this.$root.metadataFields['s_infobox_platform'], this.$root.metadataFields['s_infobox_engine'], this.$root.metadataFields['s_infobox_maxSpeed'], this.$root.metadataFields['s_infobox_height'], this.$root.metadataFields['s_infobox_width'], this.$root.metadataFields['s_infobox_length'], this.$root.metadataFields['s_infobox_weight']],
                exportedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_title'], this.$root.metadataFields['s_infobox_producer'], this.$root.metadataFields['s_infobox_parentCompany'], this.$root.metadataFields['s_infobox_countryOfOrigin'], this.$root.metadataFields['s_infobox_class'], this.$root.metadataFields['s_infobox_bodyType'], this.$root.metadataFields['s_infobox_platform'], this.$root.metadataFields['s_infobox_engine'], this.$root.metadataFields['s_infobox_maxSpeed'], this.$root.metadataFields['s_infobox_height'], this.$root.metadataFields['s_infobox_width'], this.$root.metadataFields['s_infobox_length'], this.$root.metadataFields['s_infobox_weight']]
            })
            this.metadataObjects.push({
                label: 'Kniha',
                id: 'kniha',
                hash: this.tabLabelHash('Kniha'),
                facetedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_title'], this.$root.metadataFields['s_infobox_author'], this.$root.metadataFields['s_infobox_origName'], this.$root.metadataFields['s_infobox_coAuthor'], this.$root.metadataFields['s_infobox_issueCountry'], this.$root.metadataFields['s_infobox_isbn'], this.$root.metadataFields['s_infobox_genre'], this.$root.metadataFields['s_infobox_pageCount']],
                searchRecordMetadataFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_title'], this.$root.metadataFields['s_infobox_author'], this.$root.metadataFields['s_infobox_origName'], this.$root.metadataFields['s_infobox_coAuthor'], this.$root.metadataFields['s_infobox_issueCountry'], this.$root.metadataFields['s_infobox_isbn'], this.$root.metadataFields['s_infobox_genre'], this.$root.metadataFields['s_infobox_pageCount']],
                exportedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['s_infobox_title'], this.$root.metadataFields['s_infobox_author'], this.$root.metadataFields['s_infobox_origName'], this.$root.metadataFields['s_infobox_coAuthor'], this.$root.metadataFields['s_infobox_issueCountry'], this.$root.metadataFields['s_infobox_isbn'], this.$root.metadataFields['s_infobox_genre'], this.$root.metadataFields['s_infobox_pageCount']]
            })
            this.metadataObjects.push({
                label: 'Ostatné',
                id: 'ostatne',
                hash: this.tabLabelHash('Ostatné'),
                facetedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy']],
                searchRecordMetadataFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy']],
                exportedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy']]
            })
            this.metadataObjects.push({
                label: 'Všetko',
                id: this.allResultTagId,
                hash: this.allResultTagId,
                facetedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy']],
                searchRecordMetadataFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy']],
                exportedFields: [this.$root.metadataFields['creationDate'], this.$root.metadataFields['lastModificationDate'], this.$root.metadataFields['lastModifiedBy'], this.$root.metadataFields['objectTypeId']]
            })
            document.title = this.documentTitle
        },
        setMetadataFields () {
            return { // dependOn - hierarchical dependency by id
              sourceId: { id: 'sourceId', label: "metadataFieldLabels.sourceLabel", datatype: 'string', valueFormatter: this.sourceIdValueFormatter, facet: { valueFormatter: this.sourceIdValueFormatter }, searchPhrase: '', helpPhrase: this.$t("metadataFieldsHelpPhrases.sourceHelp"), dependOn: [] },
              createdBy: { id: 'createdBy', label: "metadataFieldLabels.createdByLabel", datatype: 'string', searchPhrase: '', helpPhrase: this.$t("metadataFieldsHelpPhrases.createHelp"), dependOn: [], labelForSettlement: this.$t("metadataFieldsAlternativesPhrases.createdLabelForSettlement") },
              creationDate: { id: 'creationDate', label: "metadataFieldLabels.creationDateLabel", valueFormatter: this.datetimeFormatter, datatype: 'datetime', facet: { facetRangeGap: '/MONTH%2B1MONTH', facetRangeStart: 'NOW-30YEAR', facetRangeEnd: 'NOW', valueFormatter: this.yearMonthFormmater }, slider: { valueForSlider: [], dataForSlider: [], sliderVisibility: false }, helpPhrase: "metadataFieldsHelpPhrases.creationHelp", dependOn: [] },
              lastModificationDate: { id: 'lastModificationDate', label: "metadataFieldLabels.lastModificationDateLabel", valueFormatter: this.datetimeFormatter, datatype: 'datetime', facet: { facetRangeGap: '/MONTH%2B1MONTH', facetRangeStart: 'NOW-30YEAR', facetRangeEnd: 'NOW', valueFormatter: this.yearMonthFormmater }, slider: { valueForSlider: [], dataForSlider: [], sliderVisibility: false }, helpPhrase: "metadataFieldsHelpPhrases.lastModificationDateHelp", dependOn: []},
              lastModifiedBy: { id: 'lastModifiedBy', label: "metadataFieldsLabels.lastModifiedByLabel", datatype: 'string', searchPhrase: '', helpPhrase: "metadataFieldsHelpPhrases.lastModifiedByHelp", dependOn: [] },
              referredNumber: { id: 'referredNumber', label: "metadataFieldsLabels.referredNumberLabel", datatype: 'string', searchPhrase: '', helpPhrase: "metadataFieldsHelpPhrases.referredNumberHelp", dependOn: [], labelForSettlement: "metadataFieldsAlternativesPhrases.referredLabelForSettlement" },
              fileresponsible: { id: 'fileresponsible', label: "metadataFieldsLabels.fileresponsibleLabel", datatype: 'string', searchPhrase: '', helpPhrase: "metadataFieldsHelpPhrases.fileresponsibleHelp", dependOn: [] },
              subject: { id: 'subject', label: "metadataFieldsLabels.subjectLabel", datatype: 'string', searchPhrase: '', helpPhrase: "metadataFieldsHelpPhrases.subjectHelp", dependOn: [], labelForSettlement: "metadataFieldsAlternativesPhrases.subjectLabelForSettlement", labelForFiles: "metadataFieldsAlternativesPhrases.subjectLabelForFiles" },
              senderSign: { id: 'senderSign', label: "metadataFieldsLabels.senderSignLabel", datatype: 'string', searchPhrase: '', helpPhrase: "metadataFieldsHelpPhrases.senderSignHelp", dependOn: [] },
              objectTypeId: { id: 'objectTypeId', label: "metadataFieldsLabels.objectTypeIdLabel", datatype: 'string', searchPhrase: '', helpPhrase: "metadataFieldsHelpPhrases.objectTypeIdHelp", dependOn: [] },
              allText: { id: 'allText', label: "metadataFieldsLabels.allTextLabel", datatype: 'string', searchPhrase: '', helpPhrase: "metadataFieldsHelpPhrases.allTextHelp", dependOn: [] },
              leaveApplicant: { id: 'leaveApplicant', label: "metadataFieldsLabels.leaveApplicantLabel", datatype: 'string', searchPhrase: '', helpPhrase: "metadataFieldsHelpPhrases.leaveApplicantHelp", dependOn: [] },
              leaveApprover: { id: 'leaveApprover', label: "metadataFieldsLabels.leaveApproverLabel", datatype: 'string', searchPhrase: '', helpPhrase: "metadataFieldsHelpPhrases.leaveApproverHelp", dependOn: [] },
              leaveLocation: { id: 'leaveLocation', label: "metadataFieldsLabels.leaveLocationLabel", datatype: 'string', searchPhrase: '', helpPhrase: "metadataFieldsHelpPhrases.leaveLocationHelp", dependOn: [] },
              leaveState: { id: 'leaveState', label: "metadataFieldsLabels.leaveStateLabel", datatype: 'string', searchPhrase: '', helpPhrase: "metadataFieldsHelpPhrases.leaveStateHelp", dependOn: [] },
              leaveYear: { id: 'leaveYear', label: "metadataFieldsLabels.leaveYearLabel", datatype: 'string', searchPhrase: '', helpPhrase: "metadataFieldsHelpPhrases.leaveYearHelp", dependOn: [] },
              leaveFrom: { id: 'leaveFrom', label: "metadataFieldsLabels.leaveFromLabel", valueFormatter: this.datetimeFormatter, datatype: 'datetime', facet: { facetRangeGap: '/MONTH%2B1MONTH', facetRangeStart: 'NOW-30YEAR', facetRangeEnd: 'NOW', valueFormatter: this.yearMonthFormmater }, slider: { valueForSlider: [], dataForSlider: [], sliderVisibility: false }, helpPhrase: "metadataFieldsHelpPhrases.leaveFromHelp", dependOn: [] },
              leaveTo: { id: 'leaveTo', label: "metadataFieldsLabels.leaveToLabel", valueFormatter: this.datetimeFormatter, datatype: 'datetime', facet: { facetRangeGap: '/MONTH%2B1MONTH', facetRangeStart: 'NOW-30YEAR', facetRangeEnd: 'NOW', valueFormatter: this.yearMonthFormmater }, slider: { valueForSlider: [], dataForSlider: [], sliderVisibility: false }, helpPhrase: "metadataFieldsHelpPhrases.leaveToHelp", dependOn: [] },
              leaveDays: { id: 'leaveDays', label: "metadataFieldsLabels.leaveDaysLabel", datatype: 'number', helpPhrase: "metadataFieldsHelpPhrases.leaveDaysHelp", dependOn: [] }
            }
        },
    },
    created: function () {
        // create in other problems and tasks
        this.monthsLabels = this.setMonthLabels()
        this.metadataFields = this.setMetadataFields()
        this.setMetadataObjects()
        this.metadataConfigManager = { metadataObjects: this.metadataObjects, metadataFields: this.metadataFields, metadataGDPRFields: this.metadataGDPRFields }
    },
    computed: {
        loggedIn() {
          return this.$root.loggedInUser === "" ? false : true
        }
    },
    render: () => (h(App))
});

app.use(store).use(VueAxios, axios).use(router).use(i18n).use(SimpleTypeahead);
app.component("font-awesome-icon", FontAwesomeIcon);
export const mounted = app.mount('#app');
export {i18n};