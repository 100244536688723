<template>
    <div class="searchRecord">

        <div class="recordDetails">
            <div class="nameSection">
                <div @click="displayDocumentContent(result.id)"><img src="../../assets/icon_search.svg"></div>
                <a class="documentName" :href="modifyLink(result)" target="_blank"> {{ result.filename }}</a>
            </div>
            <p v-html="result.content_txt_sk_highlight ? result.content_txt_sk_highlight : ''"></p>
            <div class="documentMetadata">
                <div v-for="(metadataValue, metadataIndex) in result" >
                    <p class="metadataContent" v-if="showMetadata(metadataIndex, result[$root.facetFieldForTab])">  {{ setLabelForMetadata(metadataIndex) }} : {{ setValueForMetadata(metadataValue, metadataIndex) }}</p>
                </div>
            </div>
        </div>


        <div class="recordActions">
            <div class="anonymaSendContent">
                <img class="sendAnonyma" src="../../assets/anonymaLogo.svg" :title="$t('results.anonymizeDocument')" @click="setFileToAnonyma(result)">
            </div>
            <div v-if="$root.signerEnabled" class="signerSendContent">
                <img class="sendSigner" src="../../assets/icon_sign.svg" :title="$t('results.signDocument')" @click="signDocument(result)">
            </div>
        </div>
        
        
        <!-- document highlight -->
        <teleport to="body">   
            <div class="modalExport" v-show="showHighlight" @click.self="closeDocumentContent"> 
                <div class="modalViewExport">
                    <div class="highlightHeaderContent">
                        <h2 class="highlightTitle"> {{ result.filename }} </h2>
                        <div id="closeHighlight" @click="closeDocumentContent"> <i class="fa fa-xmark fa-lg" style="height: 100%;width: 100%;"></i> </div>
                    </div>
                    <p id="highlightLength" v-html="this.$store.state.HighlightText"></p>
                </div>
            </div>
        </teleport>
        <!-- document signing -->
        <teleport to="body">   
            <div class="modalExport" v-show="showSigner" @click.self="showSigner = !showSigner,signerLink = ''"> 
                <div class="modalSignerViewExport">
                    <div class="highlightSignerHeaderContent">
                        <h3 class="highlightSignerTitle"> {{ result.filename }} </h3>
                        <div id="closeSigner" @click="showSigner = !showSigner,signerLink = ''"> <i class="fa fa-xmark fa-lg" style="height: 100%;width: 100%;"></i> </div>
                    </div>
                    <div class="signerIframe">
                        <iframe class="signer" v-if="signerLink" :src="signerLink" ></iframe>
                        <h1 class="errorMessage" v-if="showErrorMessage">Externá podpisovacia služba je momentálne nedostupná</h1>
                    </div>
                </div>
            </div>
        </teleport>
    </div>
</template>

<script>
import setGoodStringFormat from '../../mixins/setGoodStringFormat'
import { useCookies } from 'vue3-cookies'
import EnvProvider from 'jvjr-docker-env';

export default {
    name: "Content",
    data () {
        return {
            showHighlight: false,
            showSigner: false,
            signerLink: '',
            showErrorMessage: false,
            DocumentText: "",
        }
    },
    props: {
        result: {
            required: false,
            type: Object
        }
    },
    setup() {
        const { cookies } = useCookies();
        return { cookies};
    },
    mixins: [
        setGoodStringFormat
    ],
    methods: {
        modifyLink (record) {
            // if (record.sourceId === 'fabasoft' && this.$root.useFabasoftRolesExtension) {
            //     var link = record.documentLink
            //     if (record.documentLink.indexOf('?') > -1) {
            //         link += '&'
            //     } else {
            //         link += '?'
            //     }
            //     return link + 'gx=' + this.$root.getCookie('gx') + '&px=' + this.$root.getCookie('px') + '&xx=' + this.$root.getCookie('xx') + '&ux=' + this.$root.getCookie('ux')
            // } 
            if (record.sourceId === 'disk') {
                return EnvProvider.value('FILESYSTEM_URL') + this.$root.FILESYSTEM_DOCUMENT + encodeURIComponent(record.documentId)
            }
            return record.documentLink
        },
        showMetadata (metadataId, tabId) {
            for (var i = 0; i < this.$root.metadataConfigManager.metadataObjects.length; i += 1) {
                var metadataObject = this.$root.metadataConfigManager.metadataObjects[i]
                if (metadataObject.id === tabId) {
                    for (var metadataFields of metadataObject.searchRecordMetadataFields) {
                        if(metadataFields.id === metadataId) {
                            return true
                        }
                    }
                }
            }
            return false
        },
        setFileToAnonyma (record) {
            var documentLink = '&documentLink='
            if (record.sourceId === 'disk') {
                documentLink += EnvProvider.value('FILESYSTEM_URL') + this.$root.FILESYSTEM_DOCUMENT + encodeURIComponent(record.documentId)
            } else {
                documentLink += record.documentLink
            }
            var anchor = document.createElement('a')
            if ( EnvProvider.value('ANONYMA_URL_COOKIES') == 'true') {
                anchor.href = EnvProvider.value('ANONYMA_URL') + '/formview?userToken=' +
                this.cookies.get('userToken') +
                '&userName=' +
                this.cookies.get('userName') +
                documentLink
            } else {
                anchor.href = EnvProvider.value('ANONYMA_URL') + '/formview?' + documentLink.substring(1)
            }
            anchor.target="_blank"
            anchor.click()
        },
        async signDocument (record) {
            this.signerLink = ''
            this.showSigner = !this.showSigner
            var doclink = ''

            // get document link
            if (record.sourceId === 'disk') {
                doclink = EnvProvider.value('FILESYSTEM_URL') + this.$root.FILESYSTEM_DOCUMENT + encodeURIComponent(record.documentId)
            } else {
                doclink = EnvProvider.value('ANONYMA_URL') + '/get/crzLink?documentLink=' + encodeURIComponent(record.documentLink)
            }

            try {
                // get the document data from disk or crz
                const response = await this.axios({
                                                    method: 'get',
                                                    url: doclink,
                                                    responseType: 'arraybuffer'
                                                });
                if (response.status != 200) {
                    console.error(response.status);
                    return;
                }

                const fileData = new Blob([response.data], { type: 'application/pdf' });
                const formData = new FormData();
                const name = record.filename.split('.')[0];
                const filename = record.filename;

                formData.append('file', fileData, filename);
                formData.append('lang', this.$i18n.locale);

                // post to signer.senseiot.sk:5020/sign-document
                const responseSigner = await this.axios.post(EnvProvider.value('SIGNER_URL') + '/sign-document', formData, {
                                                    headers: {
                                                        'Content-Type': 'multipart/form-data'
                                                    }
                                                });
                if (responseSigner.status != 200) {
                    console.error(responseSigner.status, responseSigner.data.message);
                    this.showErrorMessage = true
                    this.signerLink = null

                    return;
                }

                // get link from result and show in iframe
                this.signerLink = responseSigner.data.redirect_url;
            } catch (error) {
                if (error.response.status == 500) {
                    console.error(error.response.status, error.response.data.message);
                    this.showErrorMessage = true
                    this.signerLink = null

                    return;
                }
                else{
                    console.error(error)
                }
            }
        },

        displayDocumentContent (id) {
            this.showHighlight = !this.showHighlight
            this.$store.commit('setHighlightedId', id)
            this.$store.dispatch("fetchDocumentContext", {})
        },
        closeDocumentContent(){
            this.showHighlight = !this.showHighlight
            this.$store.commit("setHighlightText", '')
        }
    },
}
</script>
<style lang="scss" scoped src="@/assets/css/ResultContent.scss"></style>