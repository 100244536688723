<template>
    <div class="gdprSection">
      <h1>{{ $t("form.searchInPersonalData") }}</h1>
      <div>
        <button class="HideExpand" @click="showAllForm(true)" v-if="!collapseAll"> {{ $t("form.expandAll") }} </button>
        <button class="HideExpand" @click="showAllForm(false)" v-else-if="collapseAll"> {{ $t("form.collapse") }} </button>
        <button @click="changeTypeOfSearch()" class="HideExpand">
            <i class="fa fa-repeat" aria-hidden="true"></i>
            {{ $t("changeTypeOfSearch") }}
        </button>
      </div>
    </div>
    <div v-for="metadataClasses in $root.metadataConfigManager.metadataGDPRFields" class="gdprContainer">
        <div class="gdprTitle" @click="metadataClasses.showItems = !metadataClasses.showItems">
          <h2> {{ $t(String(metadataClasses.label)) }} </h2>
          <img @click="metadataClasses.showItems = !metadataClasses.showItems" v-if="!metadataClasses.showItems" src="../../assets/plus.svg"> 
          <img @click="metadataClasses.showItems = !metadataClasses.showItems" v-else-if="metadataClasses.showItems" src="../../assets/minus.svg">
        </div>
        <div v-show="metadataClasses.showItems" class="gdprForm">
          <div v-for="gdprFieldItem in metadataClasses.items" :key="gdprFieldItem">
            <div class="inlineflex">
              <p> {{ $t(String(gdprFieldItem.label)) }} </p>
              <button :title=$t(gdprFieldItem.helpPhrase)> <i class="fas fa-circle-question"></i> </button>
            </div> 
            <input v-if="gdprFieldItem.id == 'birthDate'" v-model="gdprFieldItem.searchPhrase" type="date">  

            <select v-else-if="gdprFieldItem.id == 'maritalStatus'" v-model="gdprFieldItem.searchPhrase" class="select-box">
            <option value=""></option>
            <option value="ženatý/ vydatá">ženatý/ vydatá</option>
            <option value="slobodný(á)">slobodný(á)</option>
            <option value="vdovec/ vdova">vdovec/ vdova</option>
            <option value="rozvedený(á)">rozvedený(á)</option>
            </select>

            <select v-else-if="gdprFieldItem.id == 'healthInsurance'" v-model="gdprFieldItem.searchPhrase" class="select-box">
            <option value=""></option>
            <option value="Všeobecná zdravotná poisťovňa">Všeobecná zdravotná poisťovňa</option>
            <option value="Dôvera">Dôvera</option>
            <option value="Union">Union</option>
         
            </select>

            <vue3-simple-typeahead v-else-if="gdprFieldItem.id == 'birthPlace' || gdprFieldItem.id == 'workplaceMunicipality' || gdprFieldItem.id == 'permanentResidenceMunicipality'" v-model="gdprFieldItem.searchPhrase" 
              id="typeahead_id"
              :items=this.options
              :minInputLength="1"
              :itemProjection="itemProjectionFunction"
              @selectItem="selectItemEventHandler"
              @onInput="onInputEventHandler"
              @onFocus="onFocusEventHandler"
              @onBlur="onBlurEventHandler"
            >
          </vue3-simple-typeahead>


          
          <vue3-simple-typeahead v-else-if="gdprFieldItem.id == 'workplaceZipCode' || gdprFieldItem.id == 'permanentResidenceZipCode'" v-model="gdprFieldItem.searchPhrase" 
              id="typeahead_id"
              :items=this.psc
              :minInputLength="1"
              :itemProjection="itemProjectionFunction"
              @selectItem="selectItemEventHandler"
              @onInput="onInputEventHandler"
              @onFocus="onFocusEventHandler"
              @onBlur="onBlurEventHandler"
            >
          </vue3-simple-typeahead>





            <input v-else v-model="gdprFieldItem.searchPhrase" type="text">
            
          </div>
        </div>
    </div>
  
    <button @click="callSearchWithForm()" class="searchbutton"> {{$t("leftSideMenu.searchbutton") }} </button>
</template>

<script>

import getFacetQuery from '../../mixins/getFacetQuery'
import Papa from 'papaparse';
import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'; 
export default {
    name: "Form",
    components: {
		  SimpleTypeahead,
	  },
    data() {
      return {
        isHidden: false,
        collapseAll: false,
        options: [], 
        psc: [],
      }
    },

    mixins: [
      getFacetQuery
    ],
    
    mounted(){
        document.addEventListener('keyup', this.onEnterPress);
        this.loadCSV();
    },
    methods: {
      async loadCSV() {
        console.log('loading CSV...');
        const response = await fetch("/OBCE.csv", {
          headers: {
            "Content-Type": "text/csv; charset=UTF-8",
          }
        });
        console.log(response);
        const csvData = await response.text();
        console.log(csvData);
        const results = Papa.parse(csvData, { header: true });
        this.options = results.data.map(row => row[Object.keys(row)[0]]);
        this.psc = results.data.map(row => row[Object.keys(row)[1]]).filter(Boolean);
        console.log("dlzka ",this.psc.length)
      },

      showAllForm(showItems) {
        for (var metadataClasses in this.$root.metadataConfigManager.metadataGDPRFields) {
          this.$root.metadataConfigManager.metadataGDPRFields[metadataClasses].showItems = showItems
        }
        this.collapseAll = !this.collapseAll
      },
      changeTypeOfSearch(){
          this.$root.showContextSearch = !this.$root.showContextSearch
      },
      callSearchWithForm() {
        var queryString = ''
        for(var metadataClasses in this.$root.metadataConfigManager.metadataGDPRFields) {
            for (const metadataField of Object.values(this.$root.metadataConfigManager.metadataGDPRFields[metadataClasses].items)) {
              if (typeof metadataField.searchPhrase === 'string' && metadataField.searchPhrase && metadataField.searchPhrase !== '') {
                if (queryString == '') {
                  queryString = metadataField.searchPhrase
                } else {
                  queryString += ' AND ' + metadataField.searchPhrase
                }
              }
            }
          }
          this.$store.commit('setUriParameters', {
            searchPhrase: queryString,
            itemsPerPage: this.$root.recordsPerPage,
            facetQuery: this.getFacetQuery('#All'),
            filterQuery: this.$store.state.uriParams.filterQuery,
            idQuery: this.$store.state.uriParams.idQuuery,
            tabQuery: this.$store.state.uriParams.tabQuery,
            sortQuery: this.$store.state.uriParams.sortQuery
          })
          this.$router.push("/results")
     },
     onEnterPress(event){
        if(event.key === 'Enter') {
          this.callSearchWithForm()
        }
      },
    }
}
</script>

<style lang="scss" scoped src="@/assets/css/SearchForm.scss"/>

<style>
  .gdprForm > div > .simple-typeahead > input , .select-box{
    min-width: 350px;
    height: 50px;
    border: none;
    background: #F4F4F4;
    border-radius: 16px;
    padding-inline: 1rem;
    font-size: 18px;
  }


</style>